import {
  EXPORT_DATA,
  EXPORT_DATA_BY_ORG,
  EXPORT_DATA_BY_ORG_ERROR,
  EXPORT_DATA_BY_ORG_SUCCESS,
  EXPORT_DATA_ERROR,
  EXPORT_DATA_SUCCESS,
} from "../actions";

const initialState = {
  exports: [],
  loading: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case EXPORT_DATA:
      return { ...state, loading: true };
    case EXPORT_DATA_SUCCESS:
      return { ...state, loading: false };
    case EXPORT_DATA_ERROR:
      return { ...state, error: action.payload.error, loading: false };

    case EXPORT_DATA_BY_ORG:
      return { ...state, loading: true };

    case EXPORT_DATA_BY_ORG_SUCCESS:
      return { ...state, exports: action.payload.data, loading: false };

    case EXPORT_DATA_BY_ORG_ERROR:
      return { ...state, error: action.payload.error, loading: false };
    default:
      return state;
  }
};
