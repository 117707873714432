import { AppSettings } from "@ouvidor-digital/models";
import Services from "@ouvidor-digital/persistence-services";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import { NotificationManager } from "../../components/common/react-notifications";
import { db } from "../../helpers/Firebase";
import {
  GET_APP_SETTINGS,
  GET_PROPRIETARY_COURSES_SETTINGS,
  getProprietaryCoursesSettingsError,
  getProprietaryCoursesSettingsSuccess,
  SET_APP_SETTINGS,
  SET_PROPRIETARY_COURSES_SETTINGS,
} from "../actions";

import { getAppSettingsError, getAppSettingsSuccess } from "./actions";

const { AppSettingsService, ProprietaryCourseSettingsService } = Services;

const appSettingsService = new AppSettingsService(db);
const proprietaryCourseSettingsService = new ProprietaryCourseSettingsService(db);

const toModel = (values) => {
  const newAppSettings = new AppSettings(
    values.id,
    values.orgId,
    values.preProcessing,
    values.submission,
    values.wbChat,
    values.submissionActions,
    values.submissionComments,
    values.submissionTempUsers,
    values.linkedSubmissions,
    values.updateSubmission,
    values.reports,
    values.customReports,
    values.marketingCampaign,
    values.documents,
    values.logs,
    values.settingsMembers,
    values.settingsApiKeys,
    values.settingsIntegrations,
    values.settingsAutomaticMessages,
    values.account2fa,
    values.accountSmsAlerts,
    values.accountPortalAlerts,
    values.accountEmailAlerts,
    values.wbSmsAlerts,
    values.createdDate,
    values.updatedDate,
    values.submissionFeedback,
    values.audioCaption,
    values.learningPath,
    values.cantDisableTwoFactor,
    values.builderSettings,
    values.learningPathChannelEmail,
    values.learningPathChannelSms,
    values.learningPathChannelWhatsApp,
    values.aiAnalysis,
    values.taskManager,
    values.whatsappHumanized,
    values.wbChatDocument,
  );

  return newAppSettings;
};

function* loadAppSettings({ payload }) {
  const { orgId } = payload;

  try {
    const appSettings = yield call(appSettingsService.getByIdAsync, "default", orgId);

    yield put(getAppSettingsSuccess(orgId, appSettings));
  } catch (error) {
    console.error(error);
    yield put(getAppSettingsError(error));
  }
}

function* setAppSettings({ payload }) {
  try {
    const { orgId, appSettings } = payload;
    appSettings.id = "default";

    if (orgId && appSettings) {
      const appSettingsModel = toModel({
        ...appSettings,
      });

      yield call(appSettingsService.addAsync, orgId, appSettingsModel);

      yield put(getAppSettingsSuccess(orgId, appSettingsModel));
    } else {
      console.error("Não é possível salvar as configurações de módulos do sistema");
      yield put(getAppSettingsError("Não é possível salvar as configurações de módulos do sistema"));
    }
  } catch (error) {
    console.error(error);
    yield put(getAppSettingsError(error));
  }
}

function* loadProprietaryCoursesSettings({ payload }) {
  const { orgId } = payload;

  try {
    const proprietaryCoursesSettings = yield call(proprietaryCourseSettingsService.getByIdAsync, orgId);

    yield put(getProprietaryCoursesSettingsSuccess(orgId, proprietaryCoursesSettings));
  } catch (error) {
    console.error(error);
    yield put(getProprietaryCoursesSettingsError(error));
  }
}

function* setProprietaryCoursesSettings({ payload }) {
  try {
    const { orgId, proprietaryCoursesSettings } = payload;

    if (orgId && proprietaryCoursesSettings) {
      yield call(proprietaryCourseSettingsService.addAsync, orgId, proprietaryCoursesSettings);

      yield put(getProprietaryCoursesSettingsSuccess(orgId, proprietaryCoursesSettings));

      NotificationManager.secondary(
        "Configurações de cursos proprietários salvas com sucesso",
        "Sucesso",
        5000,
        () => {},
        null,
        "filled rounded-small",
      );
    } else {
      console.error("Não é possível salvar as configurações de cursos proprietários");
      yield put(getProprietaryCoursesSettingsError("Não é possível salvar as configurações de cursos proprietários"));
    }
  } catch (error) {
    console.error(error);
    yield put(getProprietaryCoursesSettingsError(error));
  }
}

export function* watchGetAppSettings() {
  yield takeEvery(GET_APP_SETTINGS, loadAppSettings);
}

export function* watchSetAppSettings() {
  yield takeEvery(SET_APP_SETTINGS, setAppSettings);
}

export function* watchGetProprietaryCoursesSettings() {
  yield takeEvery(GET_PROPRIETARY_COURSES_SETTINGS, loadProprietaryCoursesSettings);
}

export function* watchSetProprietaryCoursesSettings() {
  yield takeEvery(SET_PROPRIETARY_COURSES_SETTINGS, setProprietaryCoursesSettings);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetAppSettings),
    fork(watchSetAppSettings),
    fork(watchGetProprietaryCoursesSettings),
    fork(watchSetProprietaryCoursesSettings),
  ]);
}
