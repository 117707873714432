import Services from "@ouvidor-digital/persistence-services";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import { auth, db, functions } from "../../helpers/Firebase";
import { GET_ALL_USERS } from "../actions";

import { getAllUsersError, getAllUsersSuccess } from "./actions";

const { UserService } = Services;
const userService = new UserService(db, auth, functions);

function* getAllUsers() {
  try {
    const users = yield call(userService.getAllAsync);

    yield put(getAllUsersSuccess(users));
  } catch (error) {
    console.error(error);
    yield put(getAllUsersError(error));
  }
}

export function* watchGetAllUsers() {
  yield takeEvery(GET_ALL_USERS, getAllUsers);
}

export default function* rootSaga() {
  yield all([fork(watchGetAllUsers)]);
}
