import { GET_EVENT_TAB_DATA, GET_EVENT_TAB_DATA_ERROR, GET_EVENT_TAB_DATA_SUCCESS } from "../../actions";

const INIT_STATE = {
  data: {},
  error: "",
  isLoading: true,
};

export default (state = INIT_STATE, action) => {
  let newState = {};

  switch (action.type) {
    case GET_EVENT_TAB_DATA:
      return { ...state, isLoading: true };

    case GET_EVENT_TAB_DATA_SUCCESS:
      newState = { ...state, isLoading: false };
      newState.data = action.payload.data;

      return newState;

    case GET_EVENT_TAB_DATA_ERROR:
      return { ...state, isLoading: true, error: action.payload };

    default:
      return { ...state };
  }
};
