import { GET_ALL_USERS, GET_ALL_USERS_ERROR, GET_ALL_USERS_SUCCESS } from "../actions";

export const getAllUsers = () => ({
  type: GET_ALL_USERS,
  payload: {},
});

export const getAllUsersSuccess = (users) => ({
  type: GET_ALL_USERS_SUCCESS,
  payload: users,
});

export const getAllUsersError = (error) => ({
  type: GET_ALL_USERS_ERROR,
  payload: error,
});
