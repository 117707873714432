import { DossierStatus } from "@ouvidor-digital/constant";
import { ContractDossier } from "@ouvidor-digital/models";
import { ContractDossierService, DossierService } from "@ouvidor-digital/persistence-services";
import { mockPerf } from "@ouvidor-digital/persistence-services/lib/interfaces/PerformanceService";
import DossierRequestService from "@ouvidor-digital/persistence-services/lib/services/DossierRequestService";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import { NotificationManager } from "../../components/common/react-notifications";
import { db, functions, perf } from "../../helpers/Firebase";
import {
  GET_DOSSIE_FORM_REQUEST,
  GET_DOSSIERS,
  getDossieFormFailure,
  getDossieFormSuccess,
  getDossiersError,
  getDossiersSuccess,
  SAVE_DOSSIE_FORM_REQUEST,
  saveDossieFormFailure,
  saveDossieFormSuccess,
  SEND_DOSSIER_TO_ORG,
  sendDossierToOrgError,
  sendDossierToOrgSuccess,
} from "../actions";
import { addOrgMediaAsync } from "../services/MediaService";

const contractDossierService = new ContractDossierService(db, perf);
const dossierRequestService = new DossierRequestService(db, mockPerf);
const dossierService = new DossierService(db, perf);

function* saveDossieForm(action) {
  const saveDossieFormFunction = async (data) => {
    const contractDossier = new ContractDossier(data.dossierPerContract, 0, data.dossierExtra);

    return await contractDossierService.setContractDossier(data.orgId, contractDossier);
  };

  try {
    yield call(saveDossieFormFunction, {
      ...action.payload,
    });

    yield put(saveDossieFormSuccess(action.payload));

    NotificationManager.secondary("Dossiê salvo com sucesso", "Sucesso", 3000, () => {}, null, "filled rounded-small");
  } catch (error) {
    console.error(error);
    yield put(saveDossieFormFailure({ error }));
    NotificationManager.error("Erro ao salvar dossiê", "Erro", 3000, () => {}, null, "filled rounded-small");
  }
}

function* getDossieForm(action) {
  const getDossieFormFunction = async (orgId) => {
    const dossierForm = await contractDossierService.getContractDossier(orgId);

    return dossierForm;
  };

  try {
    const dossieForm = yield call(getDossieFormFunction, action.payload);

    yield put(getDossieFormSuccess(dossieForm));
  } catch (error) {
    console.error(error);
    yield put(getDossieFormFailure({ error }));
  }
}

function* getDossiersRequest(action) {
  try {
    const dossiersRequests = yield call(dossierRequestService.getAllAsync);

    yield put(getDossiersSuccess(dossiersRequests));
  } catch (error) {
    yield put(getDossiersError({ error }));

    console.error(error);
  }
}

async function sendDossierToOrgAsync(dossierId, orgId, file) {
  const dossier = await dossierService.getDossierAsync(orgId, dossierId);

  if (!dossier) {
    throw new Error("Dossier not found");
  }

  const fileObject = file[0];
  const filePath = await addOrgMediaAsync(fileObject, `${orgId}/dossiers`, `${dossierId}.pdf`, {});

  dossier.documentPath = filePath;
  dossier.status = DossierStatus.RECEIVED;
  dossier.updatedDate = new Date();

  await dossierService.updateDossierAsync(orgId, dossier);
  await dossierRequestService.deleteDossierRequestAsync(dossierId);

  const dossiersRequests = await dossierRequestService.getAllAsync();

  return dossiersRequests;
}

export function* sendDossierToOrg(action) {
  const { dossierId, orgId, file, history } = action.payload;
  try {
    const dossiersRequests = yield call(sendDossierToOrgAsync, dossierId, orgId, file);

    history.push("/app/dossiers");

    yield put(sendDossierToOrgSuccess(dossiersRequests));
    yield put(sendDossierToOrgSuccess({}));
  } catch (error) {
    yield put(sendDossierToOrgError({ error }));

    console.error(error);
  }
}

export function* watchSaveDossieForm() {
  yield takeEvery(SAVE_DOSSIE_FORM_REQUEST, saveDossieForm);
}

function* watchGetDossieForm() {
  yield takeEvery(GET_DOSSIE_FORM_REQUEST, getDossieForm);
}

function* watchGetDossiers() {
  yield takeEvery(GET_DOSSIERS, getDossiersRequest);
}

function* watchSendDossierToOrg() {
  yield takeEvery(SEND_DOSSIER_TO_ORG, sendDossierToOrg);
}

export default function* rootSaga() {
  yield all([fork(watchSaveDossieForm), fork(watchGetDossieForm), fork(watchGetDossiers), fork(watchSendDossierToOrg)]);
}
