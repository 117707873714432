import {
  DELETE_WHATSAPP_SETTINGS,
  DELETE_WHATSAPP_SETTINGS_ERROR,
  DELETE_WHATSAPP_SETTINGS_SUCCESS,
  GET_CUSTOM_QUESTIONS_WHATSAPP,
  GET_CUSTOM_QUESTIONS_WHATSAPP_ERROR,
  GET_CUSTOM_QUESTIONS_WHATSAPP_SUCCESS,
  GET_WHATSAPP_SETTINGS,
  GET_WHATSAPP_SETTINGS_ERROR,
  GET_WHATSAPP_SETTINGS_SUCCESS,
  SET_CUSTOM_QUESTIONS_WHATSAPP,
  SET_CUSTOM_QUESTIONS_WHATSAPP_ERROR,
  SET_CUSTOM_QUESTIONS_WHATSAPP_SUCCESS,
  SET_WHATSAPP_CONFIGURATIONS,
  SET_WHATSAPP_CONFIGURATIONS_ERROR,
  SET_WHATSAPP_CONFIGURATIONS_SUCCESS,
  SET_WHATSAPP_SETTINGS,
  SET_WHATSAPP_SETTINGS_ERROR,
  SET_WHATSAPP_SETTINGS_SUCCESS,
} from "../actions";

export const getWhatsappSettings = (orgId) => ({
  type: GET_WHATSAPP_SETTINGS,
  payload: { orgId },
});
export const getWhatsappSettingsSuccess = (codes, orgId) => ({
  type: GET_WHATSAPP_SETTINGS_SUCCESS,
  payload: { codesWhatsappSettings: codes, orgId: orgId },
});

export const getWhatsappSettingsError = (error) => ({
  type: GET_WHATSAPP_SETTINGS_ERROR,
  payload: error,
});

export const setWhatsappSettings = (orgId, code) => ({
  type: SET_WHATSAPP_SETTINGS,
  payload: { orgId: orgId, code: code },
});

export const setWhatsappSettingsSuccess = (code) => ({
  type: SET_WHATSAPP_SETTINGS_SUCCESS,
  payload: { codesWhatsappSetting: code },
});

export const setWhatsappSettingsError = (error) => ({
  type: SET_WHATSAPP_SETTINGS_ERROR,
  payload: error,
});

export const deleteWhatsappSettings = (orgId, code) => ({
  type: DELETE_WHATSAPP_SETTINGS,
  payload: { orgId: orgId, code: code },
});

export const deleteWhatsappSettingsSuccess = (orgId, code) => ({
  type: DELETE_WHATSAPP_SETTINGS_SUCCESS,
  payload: { orgId: orgId, code: code },
});

export const deleteWhatsappSettingsError = (error) => ({
  type: DELETE_WHATSAPP_SETTINGS_ERROR,
  payload: error,
});

export const getCustomQuestionsWhatsapp = (orgId) => ({
  type: GET_CUSTOM_QUESTIONS_WHATSAPP,
  payload: { orgId },
});

export const getCustomQuestionsWhatsappSuccess = (customQuestions, orgId) => ({
  type: GET_CUSTOM_QUESTIONS_WHATSAPP_SUCCESS,
  payload: { customQuestions, orgId },
});

export const getCustomQuestionsWhatsappError = (error) => ({
  type: GET_CUSTOM_QUESTIONS_WHATSAPP_ERROR,
  payload: error,
});

export const setCustomQuestionsWhatsapp = (orgId, customQuestions) => ({
  type: SET_CUSTOM_QUESTIONS_WHATSAPP,
  payload: { orgId: orgId, customQuestions },
});

export const setCustomQuestionsWhatsappSuccess = (orgId, customQuestions) => ({
  type: SET_CUSTOM_QUESTIONS_WHATSAPP_SUCCESS,
  payload: { orgId, customQuestions },
});

export const setCustomQuestionsWhatsappError = (error) => ({
  type: SET_CUSTOM_QUESTIONS_WHATSAPP_ERROR,
  payload: error,
});

export const setWhatsappConfigurations = (orgId, configurations) => ({
  type: SET_WHATSAPP_CONFIGURATIONS,
  payload: { orgId: orgId, configurations },
});

export const setWhatsappConfigurationsSuccess = (orgId, configurations) => ({
  type: SET_WHATSAPP_CONFIGURATIONS_SUCCESS,
  payload: { orgId, configurations },
});

export const setWhatsappConfigurationsError = (error) => ({
  type: SET_WHATSAPP_CONFIGURATIONS_ERROR,
  payload: error,
});
