import {
  WORKFLOW2_ADD_SUBMISSION,
  WORKFLOW2_ADD_SUBMISSION_ERROR,
  WORKFLOW2_ADD_SUBMISSION_SUCCESS,
  WORKFLOW2_GET_SUBMISSION,
  WORKFLOW2_GET_SUBMISSION_ERROR,
  WORKFLOW2_GET_SUBMISSION_SUCCESS,
  WORKFLOW2_SST_RECORD_AUDIO,
  WORKFLOW2_SST_RECORD_AUDIO_ERROR,
  WORKFLOW2_SST_RECORD_AUDIO_SUCCESS,
  WORKFLOW2_UPDATE_SUBMISSION,
  WORKFLOW2_UPDATE_SUBMISSION_ERROR,
  WORKFLOW2_UPDATE_SUBMISSION_SUCCESS,
} from "../actions";

export const getPendingSubmission = (submissionId) => ({
  type: WORKFLOW2_GET_SUBMISSION,
  payload: { submissionId },
});
export const getPendingSubmissionSuccess = (submission) => ({
  type: WORKFLOW2_GET_SUBMISSION_SUCCESS,
  payload: { submission },
});

export const getPendingSubmissionError = (error) => ({
  type: WORKFLOW2_GET_SUBMISSION_ERROR,
  payload: error,
});

export const addPendingSubmission = (submission, history) => ({
  type: WORKFLOW2_ADD_SUBMISSION,
  payload: { submission: submission, history: history },
});

export const addPendingSubmissionSuccess = (submission) => ({
  type: WORKFLOW2_ADD_SUBMISSION_SUCCESS,
  payload: { submission: submission },
});

export const addPendingSubmissionError = (error) => ({
  type: WORKFLOW2_ADD_SUBMISSION_ERROR,
  payload: error,
});

export const updatePendingSubmission = (submission, history) => ({
  type: WORKFLOW2_UPDATE_SUBMISSION,
  payload: { submission: submission, history: history },
});

export const updatePendingSubmissionSuccess = (submission) => ({
  type: WORKFLOW2_UPDATE_SUBMISSION_SUCCESS,
  payload: { submission: submission },
});

export const updatePendingSubmissionError = (error) => ({
  type: WORKFLOW2_UPDATE_SUBMISSION_ERROR,
  payload: error,
});

export const sstRecordAudio = (recordAudioSrc) => ({
  type: WORKFLOW2_SST_RECORD_AUDIO,
  payload: { recordAudioSrc },
});

export const sstRecordAudioSuccess = (recordText) => ({
  type: WORKFLOW2_SST_RECORD_AUDIO_SUCCESS,
  payload: { recordText },
});

export const sstRecordAudioError = (error) => ({
  type: WORKFLOW2_SST_RECORD_AUDIO_ERROR,
  payload: error,
});
