import Services from "@ouvidor-digital/persistence-services";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import { db, perf } from "../../../helpers/Firebase";
import { GET_EVENT_TAB_DATA } from "../../actions";

import { getEventTabDataError, getEventTabDataSuccess } from "./actions";

const { OrgService, OrgMemberService } = Services;
const orgService = new OrgService(db, perf);
const orgMemberService = new OrgMemberService(db, perf);

function* getEventTabData({ payload }) {
  const orgId = payload;

  try {
    const org = yield call(orgService.getByIdAsync, orgId);

    const orgMembers = yield call(orgMemberService.getAllAsync, orgId);
    const users = orgMembers || [];

    const filteredUsers = users.filter((u) => {
      return u.profile.level > 0;
    });
    const reportedUsersOptions = filteredUsers.map((u, index) => {
      return {
        label: `${u.profile.name} - (${u.profile.role})`,
        value: u.profile.uid,
        key: index,
      };
    });

    const data = {
      submissionTypesOptions: org.settings.submissionTypes,
      reportedUsersOptions,
      locations: org.settings.locations,
    };

    yield put(getEventTabDataSuccess(data));
  } catch (error) {
    console.error(error);
    yield put(getEventTabDataError(error));
  }
}

export function* watchGetEventTabData() {
  yield takeEvery(GET_EVENT_TAB_DATA, getEventTabData);
}

export default function* rootSaga() {
  yield all([fork(watchGetEventTabData)]);
}
