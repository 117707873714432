import { Intls, SubmissionStatus, UserLevels } from "@ouvidor-digital/constant";
import { OrgRef, Submission, SubmissionEvent, SubmissionType, Whistleblower } from "@ouvidor-digital/models";
import Services from "@ouvidor-digital/persistence-services";
import { getValueOrNull } from "@ouvidor-digital/utils";
import { getLabel, getSelectItem } from "@ouvidor-digital/utils/lib/options";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import ANALYTICS_ACTIONS from "../../constants/AnalyticsActions";
import { analytics, db, perf } from "../../helpers/Firebase";
import {
  ADD_WORKFLOW_SUBMISSION,
  GET_SUBMISSIONS_BY_USER_LEVEL,
  UPDATE_WORKFLOW_SUBMISSION,
  WORKFLOW_GET_SUBMISSION,
} from "../actions";

import {
  getSubmissionsByUserLevelError,
  getSubmissionsByUserLevelSuccess,
  getWorkflowSubmissionError,
  getWorkflowSubmissionSuccess,
} from "./actions";

const { OrgService, PendingSubmissionService } = Services;

const {
  _submissionSensitivities,
  _submissionKeepHappening,
  _submissionConfidenceLevels,
  _submissionSource,
  _submissionStatus,
} = Intls;

const orgService = new OrgService(db, perf);
const pendingSubmissionService = new PendingSubmissionService(db, perf);
const firebaseAnalyticsService = new Services.FirebaseAnalyticsService(analytics);

const _toViewModel = (submission) => {
  const viewModel = {
    id: submission.id,
    confidenceLevel: getLabel(_submissionConfidenceLevels, submission.event.confidenceLevel),
    createdDate: submission.createdDate,
    eventDate: submission.event.date,
    description: submission.event.description,
    evidenceFiles: submission.event.evidenceFiles,
    location: submission.event.location,
    orgId: submission.orgRef.id,
    orgName: submission.orgRef.name,
    keepHappening: getLabel(_submissionKeepHappening, submission.event.keepHappening),
    protocolNumber: submission.protocolNumber,
    reportedPerson: submission.reportedPerson,
    reportedUsers: submission.reportedUsers,
    sensitivity: getLabel(_submissionSensitivities, submission.sensitivity),
    source: getLabel(_submissionSource, submission.source),
    status: getLabel(_submissionStatus, submission.status),
    type: submission.type.label,
    typeObject: submission.type,
    wbName: submission.whistleblower.name,
    wbEmail: submission.whistleblower.email,
    wbPhoneNumber: submission.whistleblower.phoneNumber,
    wbRelationship: submission.whistleblower.relationship,
    wbRole: submission.whistleblower.role,
    wbOrgName: submission.whistleblower.orgName,
    witnesses: submission.event.witnesses,
    extras: submission.extras,
  };
  return viewModel;
};

const _toWorkflowViewModel = (submission) => {
  return {
    wbData: {
      protocolNumber: submission.protocolNumber,
      wbRelationship: {
        key: 0,
        value: submission.whistleblower.relationship,
        label: submission.whistleblower.relationship,
      },
      wbRole: submission.whistleblower.role,
      wbPhoneNumber: submission.whistleblower.phoneNumber,
      wbEmail: submission.whistleblower.email,
      wbName: submission.whistleblower.name,
      wbOrgName: submission.whistleblower.orgName,
    },
    eventData: {
      type: submission.type,
      confidenceLevel: getSelectItem(_submissionConfidenceLevels, submission.event.confidenceLevel),
      status: submission.status,
      eventDate: submission.event.date,
      keepHappening: getSelectItem(_submissionKeepHappening, submission.event.keepHappening),
      description: submission.event.description,
      witnesses: submission.event.witnesses,
      source: getSelectItem(_submissionSource, submission.source),
      location: {
        key: 0,
        value: submission.event.location,
        label: submission.event.location,
      },
      evidenceFiles: submission.event.evidenceFiles,
      reportedPerson: submission.reportedPerson,
      reportedUsers: submission.reportedUsers,
      extras: submission.extras,
    },
  };
};

const _buildSubmission = (submission) => {
  const orgRef = new OrgRef(submission.org.id, submission.org.name, true);

  const submissionType = new SubmissionType(
    "",
    submission.type.label,
    submission.type.level,
    submission.type.value,
    submission.type.tag,
  );
  const whistleblower = new Whistleblower(
    submission.wbData.wbEmail,
    submission.wbData.wbName,
    submission.wbData.wbOrgName,
    submission.wbData.wbPhoneNumber,
    getValueOrNull(submission.wbData.wbRelationship),
    submission.wbData.wbRole,
  );
  const submissionEvent = new SubmissionEvent(
    getValueOrNull(submission.confidenceLevel),
    submission.eventDate,
    submission.description,
    submission.evidenceFiles || [],
    getValueOrNull(submission.keepHappening),
    getValueOrNull(submission.location),
    submission.witnesses,
  );

  const newStatus = getNextStatusByUserLevel(submission.status, submission.userLevel);

  if (newStatus === SubmissionStatus.DELIVERED) {
    delete submission.extras["_recordingUrl"];
  }

  const model = new Submission(
    submission.id,
    orgRef,
    submission.wbData.protocolNumber,
    submissionType,
    getValueOrNull(submission.source),
    newStatus,
    submission.type.level,
    whistleblower,
    submissionEvent,
    submission.reportedPerson,
    submission.reportedUsers,
    submission.tempUsers || [],
    submission.extras,
    submission.createdDate,
    new Date(),
  );

  return model;
};

const getNextStatusByUserLevel = (currentStatus, userLevel) => {
  if (userLevel === UserLevels.SYSANALYST) {
    return currentStatus === SubmissionStatus.REFUSED ? SubmissionStatus.REFUSED : SubmissionStatus.WAITING_APPROVAL;
  } else if (userLevel === UserLevels.SYSREVIEWER || userLevel === UserLevels.SYSADMIN) {
    return currentStatus === SubmissionStatus.ARCHIVED || currentStatus === SubmissionStatus.REFUSED
      ? SubmissionStatus.ARCHIVED
      : SubmissionStatus.DELIVERED;
  }

  return SubmissionStatus.PENDING;
};

function* addWorkflowSubmission({ payload }) {
  try {
    const { allSubmissions, submission } = payload;

    const newSubmission = _buildSubmission(submission);
    newSubmission.createdDate = new Date();

    yield call(pendingSubmissionService.addAsync, newSubmission);

    yield put(getSubmissionsByUserLevelSuccess(allSubmissions));
    firebaseAnalyticsService.logEvent(ANALYTICS_ACTIONS.ADMIN_MENU_WORKFLOW_NEW_SUBMISSION);
  } catch (error) {
    console.error(error);
    yield put(getSubmissionsByUserLevelError(error));
  }
}

function* updateWorkflowSubmission({ payload }) {
  try {
    const { allSubmissions, submission } = payload;

    const newSubmission = _buildSubmission(submission);
    // TODO: Fix it leaving created/updated date to workflow form
    newSubmission.createdDate = new Date();

    yield call(pendingSubmissionService.updateAsync, newSubmission);

    allSubmissions[newSubmission.id] = _toViewModel(newSubmission);

    yield put(getSubmissionsByUserLevelSuccess(allSubmissions));
    firebaseAnalyticsService.logEvent(ANALYTICS_ACTIONS.ADMIN_MENU_WORKFLOW_SUBMISSIONS);
  } catch (error) {
    console.error(error);
    yield put(getSubmissionsByUserLevelError(error));
  }
}

function* getWorkflowSubmission({ payload }) {
  const submissionId = payload;

  try {
    const submission = yield call(pendingSubmissionService.getByIdAsync, submissionId);

    if (!submission) {
      return yield put(getWorkflowSubmissionError("Submission doesn't exist"));
    }

    const vmSubmission = _toWorkflowViewModel(submission);

    // add more info necessary for submission form
    const org = yield call(orgService.getByIdAsync, submission.orgRef.id);
    vmSubmission.org = org;
    yield put(getWorkflowSubmissionSuccess(vmSubmission));
  } catch (error) {
    console.error(error);
    yield put(getWorkflowSubmissionError(error));
  }
}

function* getSubmissions({ payload }) {
  const userLevel = payload;
  try {
    // create a filter and getByFilter
    const submissions = yield call(pendingSubmissionService.getAllByUserLevelAsync, userLevel);

    const result = {};

    submissions.map((value) => {
      return (result[value.id] = _toViewModel(value));
    });

    yield put(getSubmissionsByUserLevelSuccess(result));
  } catch (error) {
    console.error(error);
    yield put(getSubmissionsByUserLevelError(error));
  }
}

export function* watchGetSubmissionsByUserLevel() {
  yield takeEvery(GET_SUBMISSIONS_BY_USER_LEVEL, getSubmissions);
}

export function* watchGetSubmission() {
  yield takeEvery(WORKFLOW_GET_SUBMISSION, getWorkflowSubmission);
}

export function* watchAddSubmission() {
  yield takeEvery(ADD_WORKFLOW_SUBMISSION, addWorkflowSubmission);
}

export function* watchUpdateSubmission() {
  yield takeEvery(UPDATE_WORKFLOW_SUBMISSION, updateWorkflowSubmission);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetSubmissionsByUserLevel),
    fork(watchGetSubmission),
    fork(watchAddSubmission),
    fork(watchUpdateSubmission),
  ]);
}
