import { FormPageSettings } from "@ouvidor-digital/models";
import Services from "@ouvidor-digital/persistence-services";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import { db } from "../../helpers/Firebase";
import { GET_FORM_PAGE_SETTINGS, SET_FORM_PAGE_SETTINGS } from "../actions";
import { addOrgMediaAsync } from "../services/MediaService";

import {
  getFormPageSettingsError,
  getFormPageSettingsSuccess,
  setFormPageSettingsError,
  setFormPageSettingsSuccess,
} from "./actions";

const { FormPageSettingsService, OrgService } = Services;

const formPageSettingsService = new FormPageSettingsService(db);
const orgService = new OrgService(db);

async function getFormPageIdAsync(orgId, lang) {
  const org = await orgService.getByIdAsync(orgId);

  const defaultLanguageValue = org.defaultLanguage.value;

  if (lang !== defaultLanguageValue) {
    return `form-${lang}`;
  }

  return "form";
}

const toObjectForm = (model) => {
  return {
    id: model.id || "form",
    orgId: model.orgId || "",
    title: model.title || "",
    color: model.color || "",
    logoUrl: model.logoUrl || "",
    disclaimerMessage: model.disclaimerMessage || "",
    protocolMessage: model.protocolMessage || "",
    availabilityMessage: model.availabilityMessage || "",
    successMessage: model.successMessage || "",
    statusValue: model.statusLink ? model.statusLink.value || "" : "",
    statusLabel: model.statusLink ? model.statusLink.label || "" : "",
    createdDate: model.createdDate || "",
    updateDate: model.updatedDate || "",
  };
};

const toModel = (object, orgId) => {
  const model = new FormPageSettings(
    object.id,
    orgId,
    object.title,
    object.color,
    object.logoUrl,
    object.disclaimerMessage,
    object.protocolMessage,
    object.availabilityMessage,
    object.successMessage,
    {
      label: object.statusLabel,
      value: object.statusValue,
    },
    object.createdDate || new Date(),
    new Date(),
  );

  return model;
};

function* loadFormPageSettings({ payload }) {
  const { orgId, lang } = payload;

  try {
    const formId = yield call(getFormPageIdAsync, orgId, lang);
    const formPageSettings = yield call(formPageSettingsService.getByIdAsync, formId, orgId);
    const formPageSettingsObject = toObjectForm(formPageSettings || {});

    yield put(getFormPageSettingsSuccess(orgId, formPageSettingsObject));
  } catch (error) {
    console.error(error);
    yield put(getFormPageSettingsError(error));
  }
}

function* setFormPageSettings({ payload }) {
  const { orgId, formPageSettings, lang } = payload;

  if (formPageSettings.logo instanceof File) {
    formPageSettings.logoUrl = yield call(addOrgMediaAsync, formPageSettings.logo, `${orgId}/formPage`, "logo", {});
  }

  try {
    const formPageId = yield call(getFormPageIdAsync, orgId, lang);
    formPageSettings.id = formPageId;

    if (!lang) {
      throw new Error("lang is required");
    }

    if (
      orgId &&
      formPageSettings.title &&
      formPageSettings.color &&
      formPageSettings.logoUrl &&
      formPageSettings.disclaimerMessage &&
      formPageSettings.protocolMessage &&
      formPageSettings.successMessage &&
      formPageSettings.availabilityMessage &&
      formPageSettings.statusLabel &&
      formPageSettings.statusValue
    ) {
      const formPageSettingsModel = toModel(formPageSettings, orgId, lang);

      yield call(formPageSettingsService.addAsync, orgId, formPageSettingsModel);

      yield put(setFormPageSettingsSuccess(orgId, formPageSettings));
    } else {
      console.error("Não é possível salvar as configurações da página de formulario");
      yield put(setFormPageSettingsError("Não é possível salvar as configurações da página de formulário"));
    }
  } catch (error) {
    console.error(error);
    yield put(setFormPageSettingsError(error));
  }
}

export function* watchGetStatusPageSettings() {
  yield takeEvery(GET_FORM_PAGE_SETTINGS, loadFormPageSettings);
}

export function* watchSetStatusPageSettings() {
  yield takeEvery(SET_FORM_PAGE_SETTINGS, setFormPageSettings);
}

export default function* rootSaga() {
  yield all([fork(watchGetStatusPageSettings), fork(watchSetStatusPageSettings)]);
}
