import Services from "@ouvidor-digital/persistence-services";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import { db } from "../../helpers/Firebase";
import { GET_VOICE_SETTINGS, getOrgSuccess, UPDATE_VOICE_SETTINGS } from "../actions";

import { getVoiceSettingsError, getVoiceSettingsSuccess } from "./actions";

const { VoiceSettingsService, SharedVoiceSettingsService, OrgService } = Services;

const voiceSettingsService = new VoiceSettingsService(db);
const sharedVoiceSettingsService = new SharedVoiceSettingsService(db);
const orgService = new OrgService(db);

function* loadVoiceSettings({ payload }) {
  const { URAPhoneNumber /* org */ } = payload;
  const DEFAULT_VOICE_SETTINGS = { options: {} };

  if (!URAPhoneNumber) {
    yield put(getVoiceSettingsSuccess(DEFAULT_VOICE_SETTINGS));
    return;
  }

  DEFAULT_VOICE_SETTINGS.id = URAPhoneNumber;

  try {
    const voiceSettings = yield call(voiceSettingsService.getByIdAsync, URAPhoneNumber);

    const sharedVoiceSettingsAll = yield call(sharedVoiceSettingsService.getAllAsync);

    yield put(getVoiceSettingsSuccess(voiceSettings || DEFAULT_VOICE_SETTINGS, sharedVoiceSettingsAll));
  } catch (error) {
    console.error(error);
    yield put(getVoiceSettingsError(error));
  }
}

function* updateVoiceSettings({ payload }) {
  try {
    const { voiceSettings, sharedNumberInfo, org } = payload;
    const { isSharedPhoneNumber, sharedPhoneNumber, sharedPhoneCode } = sharedNumberInfo;

    if (
      isSharedPhoneNumber &&
      (org?.sharedPhoneNumber !== sharedPhoneNumber || org?.sharedPhoneCode !== sharedPhoneCode)
    ) {
      const sharedVoiceSettings = yield call(sharedVoiceSettingsService.getByIdAsync, sharedPhoneNumber);
      if (!sharedVoiceSettings) {
        yield put(getVoiceSettingsError("Error: not should shared voice settings"));
      }

      // already set org in shared voice settings previously, then it must be remove
      if (
        sharedVoiceSettings.codes[org.sharedPhoneCode] === org.id ||
        (org.sharedPhoneNumber && org.sharedPhoneNumber !== sharedVoiceSettings.id)
      ) {
        const sharedVoiceSettingsOld =
          org.sharedPhoneNumber === sharedPhoneNumber
            ? sharedVoiceSettings
            : yield call(sharedVoiceSettingsService.getByIdAsync, org.sharedPhoneNumber);

        yield call(
          sharedVoiceSettingsService.deleteSharedCodeAsync,
          org.sharedPhoneCode,
          org.id,
          sharedVoiceSettingsOld,
        );
      }

      yield call(
        sharedVoiceSettingsService.setSharedCodeAsync,
        sharedPhoneCode,
        voiceSettings.orgId,
        sharedVoiceSettings,
      );

      yield call(orgService.updateGeneralAsync, org.id, {
        ...org,
        owner: {
          email: org.ownerEmail,
          name: org.ownerName,
          phoneNumber: org.ownerPhoneNumber,
        },
        sharedPhoneNumber,
        sharedPhoneCode,
      });

      const updatedOrg = { ...org, sharedPhoneNumber, sharedPhoneCode };
      yield put(getOrgSuccess(updatedOrg));
    }

    yield call(voiceSettingsService.addAsync, voiceSettings);

    const sharedVoiceSettingsAll = yield call(sharedVoiceSettingsService.getAllAsync);
    yield put(getVoiceSettingsSuccess(voiceSettings, sharedVoiceSettingsAll));
  } catch (error) {
    console.error(error);
    yield put(getVoiceSettingsError(error));
  }
}

export function* watchGetVoiceSettings() {
  yield takeEvery(GET_VOICE_SETTINGS, loadVoiceSettings);
}

export function* watchUpdateVoiceSettings() {
  yield takeEvery(UPDATE_VOICE_SETTINGS, updateVoiceSettings);
}

export default function* rootSaga() {
  yield all([fork(watchGetVoiceSettings), fork(watchUpdateVoiceSettings)]);
}
