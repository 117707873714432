import { GET_ALL_USERS, GET_ALL_USERS_ERROR, GET_ALL_USERS_SUCCESS } from "../actions";

const INIT_STATE = {
  list: [],
  error: "",
  isLoading: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ALL_USERS:
      return { ...state, isLoading: true };

    case GET_ALL_USERS_SUCCESS:
      return { ...state, isLoading: false, list: action.payload };

    case GET_ALL_USERS_ERROR:
      return { ...state, isLoading: false, error: action.payload };

    default:
      return { ...state };
  }
};
