import {
  ADD_ORG,
  DELETE_ORG_MEMBER,
  DELETE_ORG_MEMBER_ERROR,
  DELETE_ORG_MEMBER_SUCCESS,
  DISABLE_ORG,
  DISABLE_ORG_ERROR,
  DISABLE_ORG_SUCCESS,
  GET_ORG,
  GET_ORG_ERROR,
  GET_ORG_SUCCESS,
  GET_ORG_USERS,
  GET_ORG_USERS_ERROR,
  GET_ORG_USERS_SUCCESS,
  GET_ORGS,
  GET_ORGS_ERROR,
  GET_ORGS_SUCCESS,
  SET_MEMBER_ORGS,
  SET_MEMBER_ORGS_ERROR,
  SET_MEMBER_ORGS_SUCCESS,
  UPDATE_ORG,
} from "../actions";

export const getOrgs = (userLevel) => ({
  type: GET_ORGS,
  payload: userLevel,
});

export const getOrgsSuccess = (orgs) => ({
  type: GET_ORGS_SUCCESS,
  payload: { orgs },
});

export const getOrgsError = (error) => ({
  type: GET_ORGS_ERROR,
  payload: error,
});

export const getUsers = (orgId) => ({
  type: GET_ORG_USERS,
  payload: orgId,
});
export const getUsersSuccess = (users, orgId) => ({
  type: GET_ORG_USERS_SUCCESS,
  payload: { users, orgId },
});

export const getUsersError = (error) => ({
  type: GET_ORG_USERS_ERROR,
  payload: error,
});

export const deleteMember = (uid, orgId) => ({
  type: DELETE_ORG_MEMBER,
  payload: { uid, orgId },
});
export const deleteMemberSuccess = (uid, orgId) => ({
  type: DELETE_ORG_MEMBER_SUCCESS,
  payload: { uid, orgId },
});

export const deleteMemberError = (error) => ({
  type: DELETE_ORG_MEMBER_ERROR,
  payload: error,
});

export const getOrg = (orgId) => ({
  type: GET_ORG,
  payload: orgId,
});
export const getOrgSuccess = (org) => ({
  type: GET_ORG_SUCCESS,
  payload: { org },
});

export const getOrgError = (error) => ({
  type: GET_ORG_ERROR,
  payload: error,
});

export const addOrg = (org, sendFormEmail) => ({
  type: ADD_ORG,
  payload: { org, sendFormEmail },
});

export const updateOrg = (org, section, data) => ({
  type: UPDATE_ORG,
  payload: { org, section, data },
});

export const setMemberOrg = (org, member) => ({
  type: SET_MEMBER_ORGS,
  payload: { org, member },
});

export const setMemberOrgSuccess = (orgId, user) => ({
  type: SET_MEMBER_ORGS_SUCCESS,
  payload: { orgId, user },
});

export const setMemberOrgError = (error) => ({
  type: SET_MEMBER_ORGS_ERROR,
  payload: error,
});

export const disableOrg = (orgId, disable) => ({
  type: DISABLE_ORG,
  payload: { orgId, disable },
});

export const disableOrgSuccess = (orgId, disable) => ({
  type: DISABLE_ORG_SUCCESS,
  payload: { orgId, disable },
});

export const disableOrgError = (error) => ({
  type: DISABLE_ORG_ERROR,
  payload: error,
});
