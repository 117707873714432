import {
  GET_VOICE_SETTINGS,
  GET_VOICE_SETTINGS_ERROR,
  GET_VOICE_SETTINGS_SUCCESS,
  UPDATE_VOICE_SETTINGS,
} from "../actions";

export const getVoiceSettings = (URAPhoneNumber) => ({
  type: GET_VOICE_SETTINGS,
  payload: { URAPhoneNumber },
});

export const getVoiceSettingsSuccess = (voiceSettings, sharedVoiceSettings) => ({
  type: GET_VOICE_SETTINGS_SUCCESS,
  payload: { voiceSettings, sharedVoiceSettings },
});

export const getVoiceSettingsError = (error) => ({
  type: GET_VOICE_SETTINGS_ERROR,
  payload: error,
});

export const updateVoiceSettings = (voiceSettings, sharedNumberInfo, org) => ({
  type: UPDATE_VOICE_SETTINGS,
  payload: { voiceSettings, sharedNumberInfo, org },
});
