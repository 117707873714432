import {
  GET_APP_SETTINGS,
  GET_APP_SETTINGS_ERROR,
  GET_APP_SETTINGS_SUCCESS,
  GET_PROPRIETARY_COURSES_SETTINGS,
  GET_PROPRIETARY_COURSES_SETTINGS_ERROR,
  GET_PROPRIETARY_COURSES_SETTINGS_SUCCESS,
  SET_APP_SETTINGS,
  SET_PROPRIETARY_COURSES_SETTINGS,
} from "../actions";

export const getAppSettings = (orgId) => ({
  type: GET_APP_SETTINGS,
  payload: { orgId: orgId },
});

export const getAppSettingsSuccess = (orgId, appSettings) => ({
  type: GET_APP_SETTINGS_SUCCESS,
  payload: { orgId, appSettings },
});

export const getAppSettingsError = (error) => ({
  type: GET_APP_SETTINGS_ERROR,
  payload: error,
});

export const setAppSettings = (orgId, appSettings) => ({
  type: SET_APP_SETTINGS,
  payload: { orgId, appSettings },
});

export const getProprietaryCoursesSettings = (orgId) => ({
  type: GET_PROPRIETARY_COURSES_SETTINGS,
  payload: { orgId: orgId },
});

export const getProprietaryCoursesSettingsSuccess = (orgId, proprietaryCoursesSettings) => ({
  type: GET_PROPRIETARY_COURSES_SETTINGS_SUCCESS,
  payload: { orgId, proprietaryCoursesSettings },
});

export const getProprietaryCoursesSettingsError = (error) => ({
  type: GET_PROPRIETARY_COURSES_SETTINGS_ERROR,
  payload: error,
});

export const setProprietaryCoursesSettings = (orgId, proprietaryCoursesSettings) => ({
  type: SET_PROPRIETARY_COURSES_SETTINGS,
  payload: { orgId, proprietaryCoursesSettings },
});

export const setProprietaryCoursesSettingsSuccess = (orgId, proprietaryCoursesSettings) => ({
  type: SET_PROPRIETARY_COURSES_SETTINGS,
  payload: { orgId, proprietaryCoursesSettings },
});

export const setProprietaryCoursesSettingsError = (error) => ({
  type: SET_PROPRIETARY_COURSES_SETTINGS,
  payload: error,
});
