import {
  GET_STATUS_PAGE_SETTINGS,
  GET_STATUS_PAGE_SETTINGS_ERROR,
  GET_STATUS_PAGE_SETTINGS_SUCCESS,
  SET_STATUS_PAGE_SETTINGS,
} from "../actions";

const INIT_STATE = {
  orgs: {},
  error: "",
  isLoading: true,
};

export default (state = INIT_STATE, action) => {
  let newState = {};
  switch (action.type) {
    case SET_STATUS_PAGE_SETTINGS:
    case GET_STATUS_PAGE_SETTINGS:
      return { ...state, isLoading: true };

    case GET_STATUS_PAGE_SETTINGS_SUCCESS:
      newState = { ...state, isLoading: false };
      newState.orgs[action.payload.orgId] = action.payload.statusPageSettings || [];

      return newState;

    case GET_STATUS_PAGE_SETTINGS_ERROR:
      return { ...state, isLoading: false, error: action.payload };

    default:
      return { ...state };
  }
};
