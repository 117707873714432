import { NotificationManager } from "../../components/common/react-notifications";
import {
  GET_FORM_QUESTIONS_SETTINGS,
  GET_FORM_QUESTIONS_SETTINGS_ERROR,
  GET_FORM_QUESTIONS_SETTINGS_SUCCESS,
  GET_FORM_SETTINGS,
  GET_FORM_SETTINGS_ERROR,
  GET_FORM_SETTINGS_SUCCESS,
  UPDATE_FORM_QUESTIONS_SETTINGS,
  UPDATE_FORM_QUESTIONS_SETTINGS_ERROR,
  UPDATE_FORM_QUESTIONS_SETTINGS_SUCCESS,
  UPDATE_FORM_SETTINGS,
  UPDATE_FORM_SETTINGS_ERROR,
  UPDATE_FORM_SETTINGS_SUCCESS,
} from "../actions";

const INIT_STATE = {
  data: {},
  error: "",
  isLoading: true,
  isLoadingQuestions: true,
};

export default (state = INIT_STATE, action) => {
  let newState = {};
  switch (action.type) {
    case GET_FORM_SETTINGS:
      return { ...state, isLoading: true };
    case GET_FORM_SETTINGS_SUCCESS:
      newState = { ...state, isLoading: false };
      newState.data[action.payload.orgId] = {
        ...newState.data[action.payload.orgId],
        formSettings: {
          default: action.payload.formSettings,
        },
      };

      return newState;
    case GET_FORM_SETTINGS_ERROR:
      NotificationManager.error(
        "Ocorreu um erro ao buscar as configurações de formulário",
        "Erro",
        5000,
        () => {},
        null,
        "filled rounded-small",
      );
      return { ...state, isLoading: false, error: action.payload };
    case GET_FORM_QUESTIONS_SETTINGS:
      return { ...state, isLoadingQuestions: true };

    case GET_FORM_QUESTIONS_SETTINGS_SUCCESS:
      newState = { ...state, isLoadingQuestions: false };
      newState.data[action.payload.orgId] = {
        ...newState.data[action.payload.orgId],
        questionsSettings: {
          [action.payload.id]: action.payload.questionsSettings,
        },
        hasQuestions: action.payload.hasQuestions,
      };

      return newState;
    case GET_FORM_QUESTIONS_SETTINGS_ERROR:
      NotificationManager.error(
        "Ocorreu um erro ao buscar as questões do formulário",
        "Erro",
        5000,
        () => {},
        null,
        "filled rounded-small",
      );
      return { ...state, isLoadingQuestions: false, error: action.payload };
    case UPDATE_FORM_QUESTIONS_SETTINGS:
      newState = { ...state, isLoadingQuestions: true };
      return newState;

    case UPDATE_FORM_QUESTIONS_SETTINGS_SUCCESS:
      newState = { ...state, isLoadingQuestions: false };
      newState.data[action.payload.orgId] = {
        ...newState.data[action.payload.orgId],
        questionsSettings: {
          [action.payload.id]: action.payload.questionsSettings,
        },
        hasQuestions: true,
      };

      NotificationManager.secondary(
        "As questões do formulário foi atualizada",
        "Sucesso",
        5000,
        () => {},
        null,
        "filled rounded-small",
      );

      return newState;

    case UPDATE_FORM_QUESTIONS_SETTINGS_ERROR:
      NotificationManager.error(
        "Ocorreu um erro ao atualizar as questões do formulário",
        "Erro",
        5000,
        () => {},
        null,
        "filled rounded-small",
      );

      newState = { ...state, isLoadingQuestions: false };
      return newState;

    case UPDATE_FORM_SETTINGS:
      newState = { ...state, isLoading: true };
      return newState;

    case UPDATE_FORM_SETTINGS_SUCCESS:
      newState = { ...state, isLoading: false };
      newState.data[action.payload.orgId] = {
        ...newState.data[action.payload.orgId],
        formSettings: {
          [action.payload.id]: action.payload.formSettings,
        },
      };

      NotificationManager.secondary(
        "As configurações do formulário foi atualizada",
        "Sucesso",
        5000,
        () => {},
        null,
        "filled rounded-small",
      );

      return newState;

    case UPDATE_FORM_SETTINGS_ERROR:
      NotificationManager.error(
        "Ocorreu um erro ao atualizar as configurações do formulário",
        "Erro",
        5000,
        () => {},
        null,
        "filled rounded-small",
      );
      newState = { ...state, isLoading: false };
      return newState;

    default:
      return { ...state };
  }
};
