import { NotificationManager } from "../../components/common/react-notifications";
import {
  WORKFLOW2_ADD_SUBMISSION,
  WORKFLOW2_ADD_SUBMISSION_ERROR,
  WORKFLOW2_ADD_SUBMISSION_SUCCESS,
  WORKFLOW2_GET_SUBMISSION,
  WORKFLOW2_GET_SUBMISSION_ERROR,
  WORKFLOW2_GET_SUBMISSION_SUCCESS,
  WORKFLOW2_SST_RECORD_AUDIO,
  WORKFLOW2_SST_RECORD_AUDIO_ERROR,
  WORKFLOW2_SST_RECORD_AUDIO_SUCCESS,
  WORKFLOW2_UPDATE_SUBMISSION,
  WORKFLOW2_UPDATE_SUBMISSION_ERROR,
  WORKFLOW2_UPDATE_SUBMISSION_SUCCESS,
} from "../actions";

const INIT_STATE = {
  submission: {
    org: {},
    wbData: {},
    eventData: {},
  },
  error: "",
  isLoading: false,
  isLoadingSST: false,
};

export default (state = INIT_STATE, action) => {
  let newState = {};

  switch (action.type) {
    case WORKFLOW2_GET_SUBMISSION:
    case WORKFLOW2_UPDATE_SUBMISSION:
    case WORKFLOW2_ADD_SUBMISSION:
      return { ...state, isLoading: true };

    case WORKFLOW2_SST_RECORD_AUDIO:
      return { ...state, isLoadingSST: true };

    case WORKFLOW2_SST_RECORD_AUDIO_SUCCESS: {
      return {
        ...state,
        isLoadingSST: false,
        submission: {
          ...state.submission,
          eventData: {
            ...state.submission.eventData,
            description:
              state.submission.eventData.description +
              "<br/><br/>[TEXTO GERADO AUTOMATICAMENTE - Transcrição do áudio]<br/><br/>" +
              action.payload.recordText,
          },
        },
      };
    }

    case WORKFLOW2_SST_RECORD_AUDIO_ERROR: {
      return {
        ...state,
        isLoadingSST: false,
      };
    }

    case WORKFLOW2_GET_SUBMISSION_SUCCESS:
      newState = { ...state, isLoading: false, submission: action.payload.submission };

      return newState;
    case WORKFLOW2_GET_SUBMISSION_ERROR:
      newState = { ...state, isLoading: false };

      console.error(action.payload);
      NotificationManager.error(
        `Error: ${action.payload}. Verifique se o link esta correto`,
        "Não foi possível buscar este relato",
        8000,
        () => { },
        null,
        "filled rounded-small",
      );
      return newState;

    case WORKFLOW2_UPDATE_SUBMISSION_SUCCESS:
    case WORKFLOW2_ADD_SUBMISSION_SUCCESS:
      newState = { ...state, isLoading: false };

      newState.submission = {
        org: {},
        wbData: {},
        eventData: {},
      };

      NotificationManager.secondary(
        "As modificações foram salvas com sucesso",
        "Salvo com sucesso",
        8000,
        () => { },
        null,
        "filled rounded-small",
      );

      return newState;
    case WORKFLOW2_UPDATE_SUBMISSION_ERROR:
    case WORKFLOW2_ADD_SUBMISSION_ERROR:
      newState = { ...state, isLoading: false };

      console.error(action.payload);
      NotificationManager.error(
        `Error: ${action.payload}. Verifique se o link esta correto`,
        "Error: Não foi possível salvar este relato",
        8000,
        () => { },
        null,
        "filled rounded-small",
      );
      return newState;

    default:
      return { ...state };
  }
};
