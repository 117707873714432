import {
  GET_DOSSIE_FORM_ERROR,
  GET_DOSSIE_FORM_REQUEST,
  GET_DOSSIE_FORM_SUCCESS,
  GET_DOSSIERS,
  GET_DOSSIERS_ERROR,
  GET_DOSSIERS_SUCCESS,
  SAVE_DOSSIE_FORM_ERROR,
  SAVE_DOSSIE_FORM_REQUEST,
  SAVE_DOSSIE_FORM_SUCCESS,
  SEND_DOSSIER_TO_ORG,
  SEND_DOSSIER_TO_ORG_ERROR,
  SEND_DOSSIER_TO_ORG_SUCCESS,
} from "../actions";

const initialState = {
  dossiers: [],
  fetchDossiersLoading: false,
  dossieForm: null,
  loading: false,
  sendDossierLoading: false,
  error: null,
};

const dossieReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_DOSSIE_FORM_REQUEST:
    case GET_DOSSIE_FORM_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case SAVE_DOSSIE_FORM_SUCCESS:
    case GET_DOSSIE_FORM_SUCCESS:
      return {
        ...state,
        loading: false,
        dossieForm: action.payload,
      };

    case SAVE_DOSSIE_FORM_ERROR:
    case GET_DOSSIE_FORM_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case GET_DOSSIERS:
      return {
        ...state,
        fetchDossiersLoading: true,
      };

    case GET_DOSSIERS_SUCCESS:
      return {
        ...state,
        fetchDossiersLoading: false,
        dossiers: action.payload,
      };

    case GET_DOSSIERS_ERROR:
      return {
        ...state,
        fetchDossiersLoading: false,
        error: action.payload,
      };

    case SEND_DOSSIER_TO_ORG:
      return {
        ...state,
        sendDossierLoading: true,
      };

    case SEND_DOSSIER_TO_ORG_SUCCESS:
      return {
        ...state,
        sendDossierLoading: false,
        dossiers: action.payload,
      };

    case SEND_DOSSIER_TO_ORG_ERROR:
      return {
        ...state,
        sendDossierLoading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default dossieReducer;
