import {
  LOGIN_USER,
  LOGIN_USER_ERROR,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER,
  RECOVERY_PASSWORD,
  RECOVERY_PASSWORD_ERROR,
  RECOVERY_PASSWORD_SUCCESS,
  REFRESH_USER,
  RESET_PASSWORD,
  RESET_PASSWORD_ERROR,
  RESET_PASSWORD_SUCCESS,
  SET_PREFERENCE,
  SET_PREFERENCE_ERROR,
  SET_PREFERENCE_SUCCESS,
} from "../actions";

export const loginUser = (user, history) => ({
  type: LOGIN_USER,
  payload: { user, history },
});

export const loginUserSuccess = (user) => ({
  type: LOGIN_USER_SUCCESS,
  payload: user,
});

export const refreshUser = (user) => ({
  type: REFRESH_USER,
  payload: user,
});

export const loginUserError = (error) => ({
  type: LOGIN_USER_ERROR,
  payload: error,
});

export const logoutUser = (history, user) => ({
  type: LOGOUT_USER,
  payload: { history, user },
});

export const recoveryPassword = (email, history) => ({
  type: RECOVERY_PASSWORD,
  payload: { email: email, history },
});

export const recoveryPasswordError = (error) => ({
  type: RECOVERY_PASSWORD_ERROR,
  payload: error,
});

export const recoveryPasswordSuccess = (info) => ({
  type: RECOVERY_PASSWORD_SUCCESS,
  payload: info,
});

export const resetPassword = (oobCode, password, history) => ({
  type: RESET_PASSWORD,
  payload: { oobCode: oobCode, password: password, history },
});

export const resetPasswordError = (error) => ({
  type: RESET_PASSWORD_ERROR,
  payload: error,
});

export const resetPasswordSuccess = (info) => ({
  type: RESET_PASSWORD_SUCCESS,
  payload: info,
});

export const setPreference = (user, preference) => ({
  type: SET_PREFERENCE,
  payload: { preference, user },
});

export const setPreferenceError = (error) => ({
  type: SET_PREFERENCE_ERROR,
  payload: error,
});

export const setPreferenceSuccess = (info, newPreference) => ({
  type: SET_PREFERENCE_SUCCESS,
  payload: { info, newPreference },
});
