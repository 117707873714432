import {
  CREATE_COMMITTEE,
  CREATE_COMMITTEE_ERROR,
  CREATE_COMMITTEE_SUCCESS,
  DELETE_COMMITTEE,
  DELETE_COMMITTEE_ERROR,
  DELETE_COMMITTEE_SUCCESS,
  GET_COMMITTEES,
  GET_COMMITTEES_ERROR,
  GET_COMMITTEES_SUCCESS,
  UPDATE_COMMITTEE,
  UPDATE_COMMITTEE_ERROR,
  UPDATE_COMMITTEE_SUCCESS,
} from "../actions";

const initialState = {
  committees: {},
  loading: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CREATE_COMMITTEE_SUCCESS: {
      const { payload } = action;
      const newCommittee = payload.committee;
      const orgId = newCommittee.orgId;

      const currentOrgCommittees = state.committees[orgId] || [];
      const newOrgCommittees = [...currentOrgCommittees, newCommittee];

      return {
        ...state,
        committees: {
          ...state.committees,
          [orgId]: newOrgCommittees,
        },
        loading: false,
      };
    }

    case DELETE_COMMITTEE_SUCCESS: {
      const { payload } = action;

      const { committeeId, orgId } = payload;
      const orgCommittees = state.committees[orgId] || [];
      const newOrgCommittees = orgCommittees.filter((committee) => committee.id !== committeeId);
      return {
        ...state,
        committees: {
          ...state.committees,
          [orgId]: newOrgCommittees,
        },
        loading: false,
      };
    }

    case UPDATE_COMMITTEE_SUCCESS: {
      const { payload } = action;

      const updatedCommittee = payload.committee;
      const orgId = updatedCommittee.orgId;

      const orgCommittees = state.committees[orgId] || [];
      const updatedOrgCommittees = orgCommittees.map((committee) =>
        committee.id === updatedCommittee.id ? updatedCommittee : committee,
      );

      return {
        ...state,
        committees: {
          ...state.committees,
          [orgId]: updatedOrgCommittees,
        },
        loading: false,
      };
    }

    case GET_COMMITTEES_SUCCESS: {
      const { payload } = action;

      const fetchedCommittees = payload.committees;
      const orgId = payload.orgId;

      return {
        ...state,
        committees: {
          ...state.committees,
          [orgId]: fetchedCommittees,
        },
        loading: false,
      };
    }

    case CREATE_COMMITTEE:
      return { ...state, loading: true };

    case DELETE_COMMITTEE:
      return { ...state, loading: true };

    case UPDATE_COMMITTEE:
      return { ...state, loading: true };

    case GET_COMMITTEES:
      return { ...state, loading: true };

    case CREATE_COMMITTEE_ERROR:
      return { ...state, error: action.payload, loading: false };

    case DELETE_COMMITTEE_ERROR:
      return { ...state, error: action.payload, loading: false };

    case UPDATE_COMMITTEE_ERROR:
      return { ...state, error: action.payload, loading: false };

    case GET_COMMITTEES_ERROR:
      return { ...state, error: action.payload, loading: false };

    default:
      return state;
  }
};
