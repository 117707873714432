import {
  GENERATE_AI_SUGGESTION,
  GENERATE_AI_SUGGESTION_ERROR,
  GENERATE_AI_SUGGESTION_SUCCESS,
  GET_AI_SUGGESTION,
  GET_AI_SUGGESTION_ERROR,
  GET_AI_SUGGESTION_SUCCESS,
  UPDATE_SUBMISSION_SUGGESTION,
  UPDATE_SUBMISSION_SUGGESTION_ERROR,
  UPDATE_SUBMISSION_SUGGESTION_SUCCESS,
} from "../actions";

const initialState = {
  suggestions: {},
  loading: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_AI_SUGGESTION:
    case UPDATE_SUBMISSION_SUGGESTION:
    case GENERATE_AI_SUGGESTION:
      return {
        ...state,
        loading: true,
      };

    case GENERATE_AI_SUGGESTION_SUCCESS:
    case GET_AI_SUGGESTION_SUCCESS: {
      const { submissionId, preTreatmentSuggestions } = action.payload;
      return {
        ...state,
        suggestions: {
          ...state.suggestions,
          [submissionId]: {
            ...(state.suggestions[submissionId] || {}),
            preTreatmentSuggestions,
          },
        },
        loading: false,
      };
    }

    case UPDATE_SUBMISSION_SUGGESTION_SUCCESS: {
      return { ...state, loading: false };
    }

    case GET_AI_SUGGESTION_ERROR:
    case GENERATE_AI_SUGGESTION_ERROR:
    case UPDATE_SUBMISSION_SUGGESTION_ERROR:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};
