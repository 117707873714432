import { Intls, SubmissionStatus } from "@ouvidor-digital/constant";
import {
  OrgRef,
  Submission,
  SubmissionCustomField,
  SubmissionEvent,
  SubmissionType,
  Whistleblower,
} from "@ouvidor-digital/models";
import Services from "@ouvidor-digital/persistence-services";
import { getValueOrNull } from "@ouvidor-digital/utils";
import { getSelectItem } from "@ouvidor-digital/utils/lib/options";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import { db, functions, perf } from "../../helpers/Firebase";
import {
  WORKFLOW2_ADD_SUBMISSION,
  WORKFLOW2_GET_SUBMISSION,
  WORKFLOW2_SST_RECORD_AUDIO,
  WORKFLOW2_UPDATE_SUBMISSION,
} from "../actions";

import {
  addPendingSubmissionSuccess,
  getPendingSubmissionError,
  getPendingSubmissionSuccess,
  sstRecordAudioError,
  sstRecordAudioSuccess,
  updatePendingSubmissionError,
  updatePendingSubmissionSuccess,
} from "./actions";

const { _submissionKeepHappening, _submissionConfidenceLevels, _submissionSource } = Intls;
const { PendingSubmissionService, OrgService, CustomFieldService } = Services;

const pendingSubmissionService = new PendingSubmissionService(db, perf);
const orgService = new OrgService(db, perf);
const customFieldService = new CustomFieldService(db, perf);

export const _buildSubmission = (submission) => {
  const orgRef = new OrgRef(submission.org.id, submission.org.name, false, submission.org.type);

  const submissionType = new SubmissionType(
    submission.type.id,
    submission.type.label,
    submission.type.level,
    submission.type.value,
    submission.type.tag,
  );

  const whistleblower = new Whistleblower(
    submission.wbEmail,
    submission.wbName,
    submission.wbOrgName,
    submission.wbPhoneNumber,
    getValueOrNull(submission.wbRelationship),
    submission.wbRole,
  );

  const submissionEvent = new SubmissionEvent(
    getValueOrNull(submission.confidenceLevel),
    submission.eventDate,
    submission.description,
    submission.evidenceFiles || [],
    getValueOrNull(submission.keepHappening),
    getValueOrNull(submission.location),
    submission.witnesses,
  );

  if (submission.status === SubmissionStatus.DELIVERED) {
    delete submission.extras["_recordingUrl"];
  }

  const model = new Submission(
    submission.id,
    orgRef,
    submission.protocolNumber,
    submissionType,
    getValueOrNull(submission.source),
    submission.status,
    submission.type.level,
    whistleblower,
    submissionEvent,
    submission.reportedPerson,
    submission.reportedUsers,
    submission.tempUsers || [],
    submission.extras,
    submission.createdDate,
    new Date(),
  );

  return model;
};

const _toWorkflowViewModel = (submission) => {
  return {
    wbData: {
      protocolNumber: submission.protocolNumber,
      wbRelationship: {
        key: 0,
        value: submission.whistleblower.relationship,
        label: submission.whistleblower.relationship,
      },
      wbRole: submission.whistleblower.role,
      wbPhoneNumber: submission.whistleblower.phoneNumber,
      wbEmail: submission.whistleblower.email,
      wbName: submission.whistleblower.name,
      wbOrgName: submission.whistleblower.orgName,
    },
    eventData: {
      type: submission.type,
      confidenceLevel: getSelectItem(_submissionConfidenceLevels, submission.event.confidenceLevel),
      status: submission.status,
      eventDate: submission.event.date,
      keepHappening: getSelectItem(_submissionKeepHappening, submission.event.keepHappening),
      description: submission.event.description,
      witnesses: submission.event.witnesses,
      source: getSelectItem(_submissionSource, submission.source),
      location: {
        key: 0,
        value: submission.event.location,
        label: submission.event.location,
      },
      evidenceFiles: submission.event.evidenceFiles,
      reportedPerson: submission.reportedPerson,
      reportedUsers: submission.reportedUsers,
      extras: submission.extras,
    },
    id: submission.id,
    createdDate: submission.createdDate,
  };
};

function* loadPendingSubmission({ payload }) {
  const { submissionId } = payload;

  try {
    const pendingSubmission = yield call(pendingSubmissionService.getByIdAsync, submissionId);
    const vmSubmission = _toWorkflowViewModel(pendingSubmission);

    const org = yield call(orgService.getByIdAsync, pendingSubmission.orgRef.id);
    const customFields = yield call(customFieldService.getAllAsync, pendingSubmission.orgRef.id);

    org.settings = {
      ...org.settings,
      submissionCustomFields: customFields.map((cf) => new SubmissionCustomField(cf.id, cf.label, cf.value)),
    };

    vmSubmission.org = org;

    yield put(getPendingSubmissionSuccess(vmSubmission));
  } catch (error) {
    console.error(error);
    yield put(getPendingSubmissionError(error));
  }
}

function* updatePendingSubmission({ payload }) {
  const { submission, history } = payload;

  try {
    const submissionModel = _buildSubmission(submission);

    const submissionUpdated = yield call(pendingSubmissionService.updateAsync, submissionModel);
    yield put(updatePendingSubmissionSuccess(submissionUpdated));

    history.push("/app/workflow");
  } catch (error) {
    console.error(error);
    yield put(updatePendingSubmissionError(error));
  }
}

function* addPendingSubmission({ payload }) {
  const { submission, history } = payload;

  try {
    const submissionModel = _buildSubmission(submission);
    submissionModel.createdDate = new Date();

    if (!submissionModel.source) {
      submissionModel.source = {};
    }

    const submissionAdded = yield call(pendingSubmissionService.addAsync, submissionModel);
    yield put(addPendingSubmissionSuccess(submissionAdded));

    history.push("/app/workflow");
  } catch (error) {
    console.error(error);
    yield put(updatePendingSubmissionError(error));
  }
}

const getTextByEncodedAudio = async (audioEncodedUrl) => {
  const speechToText = functions.httpsCallable("speechToText2", { timeout: 540000 });

  const { data } = await speechToText({ audioEncodedUrl });

  return data;
};

function* sstRecordingAudioSubmission({ payload }) {
  const { recordAudioSrc } = payload;

  const audioEncodedUrl = encodeURIComponent(recordAudioSrc);

  try {
    const recordText = yield call(getTextByEncodedAudio, audioEncodedUrl);

    yield put(sstRecordAudioSuccess(recordText));
  } catch (error) {
    console.error(error);
    yield put(sstRecordAudioError(error));
  }
}

export function* watchGetPendingSubmission() {
  yield takeEvery(WORKFLOW2_GET_SUBMISSION, loadPendingSubmission);
}

export function* watchUpdatePendingSubmission() {
  yield takeEvery(WORKFLOW2_UPDATE_SUBMISSION, updatePendingSubmission);
}

export function* watchAddPendingSubmission() {
  yield takeEvery(WORKFLOW2_ADD_SUBMISSION, addPendingSubmission);
}

export function* watchSSTRecordingAudioSubmission() {
  yield takeEvery(WORKFLOW2_SST_RECORD_AUDIO, sstRecordingAudioSubmission);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetPendingSubmission),
    fork(watchUpdatePendingSubmission),
    fork(watchAddPendingSubmission),
    fork(watchSSTRecordingAudioSubmission),
  ]);
}
