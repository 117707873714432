import { NotificationManager } from "../../components/common/react-notifications";
import {
  GET_FORM_PAGE_SETTINGS,
  GET_FORM_PAGE_SETTINGS_ERROR,
  GET_FORM_PAGE_SETTINGS_SUCCESS,
  SET_FORM_PAGE_SETTINGS,
  SET_FORM_PAGE_SETTINGS_ERROR,
  SET_FORM_PAGE_SETTINGS_SUCCESS,
} from "../actions";

const INIT_STATE = {
  orgs: {},
  error: "",
  isLoading: true,
};

export default (state = INIT_STATE, action) => {
  let newState = {};
  switch (action.type) {
    case GET_FORM_PAGE_SETTINGS:
    case SET_FORM_PAGE_SETTINGS:
      return { ...state, isLoading: true };

    case GET_FORM_PAGE_SETTINGS_SUCCESS:
      newState = { ...state, isLoading: false };
      newState.orgs[action.payload.orgId] = action.payload.formPageSettings;

      return newState;
    case SET_FORM_PAGE_SETTINGS_SUCCESS:
      NotificationManager.secondary(
        "Configuração da página de formulario salva com sucesso!",
        "Pronto",
        5000,
        () => {},
        null,
        "filled rounded-small",
      );
      newState = { ...state, isLoading: false };
      newState.orgs[action.payload.orgId] = action.payload.formPageSettings;

      return newState;

    case GET_FORM_PAGE_SETTINGS_ERROR:
    case SET_FORM_PAGE_SETTINGS_ERROR:
      NotificationManager.error(
        "Ocorreu um error tente novamente mais tarde!",
        "Error",
        5000,
        () => {},
        null,
        "filled rounded-small",
      );
      return { ...state, isLoading: false, error: action.payload.error };

    default:
      return { ...state };
  }
};
