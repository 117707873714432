import { WhatsappSettings } from "@ouvidor-digital/models";
import Services from "@ouvidor-digital/persistence-services";
import { mockPerf } from "@ouvidor-digital/persistence-services/lib/interfaces/PerformanceService";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import { NotificationManager } from "../../components/common/react-notifications";
import { db } from "../../helpers/Firebase";
import {
  DELETE_WHATSAPP_SETTINGS,
  GET_CUSTOM_QUESTIONS_WHATSAPP,
  GET_WHATSAPP_SETTINGS,
  SET_CUSTOM_QUESTIONS_WHATSAPP,
  SET_WHATSAPP_CONFIGURATIONS,
  SET_WHATSAPP_SETTINGS,
} from "../actions";

import {
  deleteWhatsappSettingsError,
  deleteWhatsappSettingsSuccess,
  getCustomQuestionsWhatsappError,
  getCustomQuestionsWhatsappSuccess,
  getWhatsappSettingsError,
  getWhatsappSettingsSuccess,
  setCustomQuestionsWhatsappError,
  setCustomQuestionsWhatsappSuccess,
  setWhatsappConfigurationsError,
  setWhatsappConfigurationsSuccess,
  setWhatsappSettingsError,
  setWhatsappSettingsSuccess,
} from "./actions";

const { WhatsappSettingsService, CustomFieldService } = Services;

const whatsappSettingsService = new WhatsappSettingsService(db);
const customFieldService = new CustomFieldService(db, mockPerf);

function* loadWhatsappSettings({ payload }) {
  const { orgId } = payload;

  try {
    const codesWhatsappSettings = yield call(whatsappSettingsService.getByFilterAsync, [["orgId", "==", orgId]]);

    yield put(getWhatsappSettingsSuccess(codesWhatsappSettings, orgId));
  } catch (error) {
    console.error(error);
    yield put(getWhatsappSettingsError(error));
  }
}

function* updateWhatsappSettings({ payload }) {
  try {
    const { code, orgId } = payload;

    const hasWhatsappSettingsCode = yield call(whatsappSettingsService.getByIdAsync, code);

    const whatsappSettings = yield call(whatsappSettingsService.getByFilterAsync, [["orgId", "==", orgId]]);

    const whatsappSettingWithDifferentOrg = whatsappSettings.filter((wppSetting) => wppSetting.id !== code);

    if (!hasWhatsappSettingsCode || (hasWhatsappSettingsCode && hasWhatsappSettingsCode.orgId === orgId)) {
      const whatsappSettings = new WhatsappSettings(
        code,
        orgId,
        new Date(),
        new Date(),
        whatsappSettingWithDifferentOrg[0]?.customQuestions || [],
      );
      yield call(whatsappSettingsService.addAsync, whatsappSettings);
      yield put(setWhatsappSettingsSuccess(whatsappSettings));
    } else {
      console.error("Esse código já esta sendo usado para outra organização!");
      yield put(setWhatsappSettingsError("Esse código já esta sendo usado para outra organização!"));
    }
  } catch (error) {
    console.error(error);
    yield put(setWhatsappSettingsError(error));
  }
}

function* deleteWhatsappSettings({ payload }) {
  try {
    const { orgId, code } = payload;

    yield call(whatsappSettingsService._deleteDocAsync, code);
    yield put(deleteWhatsappSettingsSuccess(orgId, code));
  } catch (error) {
    console.error(error);
    yield put(deleteWhatsappSettingsError(error));
  }
}

function* loadCustomQuestionsWhatsapp({ payload }) {
  try {
    const { orgId } = payload;

    const codesWhatsappSettings = yield call(whatsappSettingsService.getByFilterAsync, [["orgId", "==", orgId]]);

    const customQuestions = codesWhatsappSettings[0]?.customQuestions || [];

    yield put(getCustomQuestionsWhatsappSuccess(customQuestions));
  } catch (error) {
    console.error(error);
    yield put(getCustomQuestionsWhatsappError(error));
  }
}

function* updateCustomQuestionsWhatsapp({ payload }) {
  try {
    const { customQuestions, orgId } = payload;

    const customFieldIds = customQuestions.map((question) => question.customFieldId);
    if (new Set(customFieldIds).size !== customFieldIds.length) {
      NotificationManager.error(
        "Não é permitido ter campos personalizados duplicados!",
        "Erro",
        5000,
        () => {},
        null,
        "filled rounded-small",
      );

      return;
    }

    const customFields = yield call(customFieldService.getAllAsync, orgId);

    const customFieldsIds = customFields.map((field) => `extras-${field.label}`);

    for (const customQuestion of customQuestions) {
      const hasCustomQuestion = customFieldsIds.some((field) => field === customQuestion.customFieldId);

      if (!hasCustomQuestion) {
        NotificationManager.error(
          `O campo personalizado ${customQuestion.customFieldId} não foi encontrado na organização!`,
          "Erro",
          5000,
          () => {},
          null,
          "filled rounded-small",
        );

        yield put(setCustomQuestionsWhatsappError("Campo personalizado não encontrado na organização!"));
        return;
      }
    }

    const codesWhatsappSettings = yield call(whatsappSettingsService.getByFilterAsync, [["orgId", "==", orgId]]);

    for (const codeWhatsappSetting of codesWhatsappSettings) {
      codeWhatsappSetting.customQuestions = customQuestions;
      yield call(whatsappSettingsService.updateCustomQuestionsAsync, codeWhatsappSetting?.id, customQuestions);
    }

    NotificationManager.success(
      "Perguntas personalizadas salvas com sucesso!",
      "Sucesso",
      5000,
      () => {},
      null,
      "filled rounded-small",
    );

    yield put(setCustomQuestionsWhatsappSuccess(orgId, customQuestions));
  } catch (error) {
    console.error(error);
    yield put(setCustomQuestionsWhatsappError(error));
  }
}

function* updateConfigurationWhatsApp({ payload }) {
  try {
    const { orgId, configurations } = payload;

    const codesWhatsappSettings = yield call(whatsappSettingsService.getByFilterAsync, [["orgId", "==", orgId]]);

    for (const codeWhatsappSetting of codesWhatsappSettings) {
      codeWhatsappSetting.configurations = configurations;
      yield call(whatsappSettingsService.updateConfigurationsAsync, codeWhatsappSetting?.id, configurations);
    }

    NotificationManager.success(
      "Configurações salvas com sucesso!",
      "Sucesso",
      5000,
      () => {},
      null,
      "filled rounded-small",
    );

    yield put(setWhatsappConfigurationsSuccess(orgId, configurations));
  } catch (error) {
    console.error(error);
    yield put(setWhatsappConfigurationsError(error));
  }
}

export function* watchSetWhatsappConfigurations() {
  yield takeEvery(SET_WHATSAPP_CONFIGURATIONS, updateConfigurationWhatsApp);
}

export function* watchGetWhatsappSettings() {
  yield takeEvery(GET_WHATSAPP_SETTINGS, loadWhatsappSettings);
}

export function* watchSetWhatsappSettings() {
  yield takeEvery(SET_WHATSAPP_SETTINGS, updateWhatsappSettings);
}

export function* watchDeleteWhatsappSettings() {
  yield takeEvery(DELETE_WHATSAPP_SETTINGS, deleteWhatsappSettings);
}

export function* watchGetCustomQuestionsWhatsapp() {
  yield takeEvery(GET_CUSTOM_QUESTIONS_WHATSAPP, loadCustomQuestionsWhatsapp);
}

export function* watchSetCustomQuestionsWhatsapp() {
  yield takeEvery(SET_CUSTOM_QUESTIONS_WHATSAPP, updateCustomQuestionsWhatsapp);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetWhatsappSettings),
    fork(watchSetWhatsappSettings),
    fork(watchDeleteWhatsappSettings),
    fork(watchGetCustomQuestionsWhatsapp),
    fork(watchSetCustomQuestionsWhatsapp),
    fork(watchSetWhatsappConfigurations),
  ]);
}
