import { Contract } from "@ouvidor-digital/models";
import { ContractStatus } from "@ouvidor-digital/models/lib/Contract";
import Services from "@ouvidor-digital/persistence-services";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import { db, perf } from "../../helpers/Firebase";
import { CHANGE_CONTRACT_STATUS, CREATE_CONTRACT, GET_CONTRACTS, UPDATE_CONTRACT } from "../actions";

import {
  changeContractStatusError,
  changeContractStatusSuccess,
  createContractError,
  createContractSuccess,
  getContractsError,
  getContractsSuccess,
  updateContractError,
  updateContractSuccess,
} from "./actions";

const { ContractService } = Services;

const contractService = new ContractService(db, perf);

function* getContracts() {
  try {
    const contracts = yield call(contractService.getAllAsync);

    yield put(getContractsSuccess(contracts));
  } catch (error) {
    yield put(getContractsError(error));
    console.error(error);
  }
}

function* createContract({ payload }) {
  try {
    const contract = new Contract(
      payload.id,
      payload.orgs.map((org) => {
        return { id: org.value, name: org.label, type: org.type };
      }),
      payload.services.map((service) => service.value),
      0,
      ContractStatus.ACTIVE,
      new Date(),
    );

    yield call(contractService.setAsync, contract);

    yield put(createContractSuccess(contract));
  } catch (error) {
    yield put(createContractError(error));
    console.error(error);
  }
}

function* updateContract({ payload }) {
  try {
    const contract = yield call(contractService._getDocAsync, payload.id);

    if (!contract) {
      throw new Error("Contrat not found");
    }

    contract.orgs = payload.orgs.map((org) => {
      return { id: org.value, name: org.label, type: org.type };
    });

    contract.services = payload.services.map((service) => service.value);

    contract.updatedDate = new Date();

    yield call(contractService.setAsync, contract);

    yield put(updateContractSuccess(contract));
  } catch (error) {
    yield put(updateContractError(error));
    console.error(error);
  }
}

function* changeContractStatus({ payload }) {
  try {
    const contract = yield call(contractService._getDocAsync, payload.contractId);

    contract.status = payload.status;

    yield call(contractService.setAsync, contract);

    yield put(changeContractStatusSuccess(payload.contractId, payload.status));
  } catch (error) {
    yield put(changeContractStatusError(error));
    console.error(error);
  }
}

export function* watchGetContracts() {
  yield takeEvery(GET_CONTRACTS, getContracts);
}

export function* watchCreateContract() {
  yield takeEvery(CREATE_CONTRACT, createContract);
}

export function* watchChangeContractStatus() {
  yield takeEvery(CHANGE_CONTRACT_STATUS, changeContractStatus);
}

export function* watchUpdateeContractStatus() {
  yield takeEvery(UPDATE_CONTRACT, updateContract);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetContracts),
    fork(watchCreateContract),
    fork(watchChangeContractStatus),
    fork(watchUpdateeContractStatus),
  ]);
}
