import { defaultColor, isDarkSwitchActive, themeColorStorageKey } from "./constants/defaultValues";

import "./assets/css/vendor/bootstrap.min.css";
import "./assets/css/vendor/bootstrap.rtl.only.min.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "./assets/css/sass/common.scss";
const color =
  isDarkSwitchActive && localStorage.getItem(themeColorStorageKey)
    ? localStorage.getItem(themeColorStorageKey)
    : defaultColor;

localStorage.setItem(themeColorStorageKey, color);
const render = () => {
  import("./assets/css/sass/themes/gogo." + color + ".scss").then(() => {
    require("./AppRenderer");
  });
};

// dom
const script = document.createElement("script");
script.type = "text/javascript";
script.async = true;
script.onload = function () {
  // remote script has loaded
};

script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_CUSTOM_RECAPTCHA_KEY}`;
document.getElementsByTagName("head")[0].appendChild(script);

render();
