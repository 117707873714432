import {
  CHANGE_CONTRACT_STATUS,
  CHANGE_CONTRACT_STATUS_ERROR,
  CHANGE_CONTRACT_STATUS_SUCCESS,
  CREATE_CONTRACT,
  CREATE_CONTRACT_ERROR,
  CREATE_CONTRACT_SUCCESS,
  GET_CONTRACTS,
  GET_CONTRACTS_ERROR,
  GET_CONTRACTS_SUCCESS,
  UPDATE_CONTRACT,
  UPDATE_CONTRACT_ERROR,
  UPDATE_CONTRACT_SUCCESS,
} from "../actions";

export const getContracts = () => {
  return {
    type: GET_CONTRACTS,
  };
};

export const getContractsSuccess = (contracts) => {
  return {
    type: GET_CONTRACTS_SUCCESS,
    payload: contracts,
  };
};

export const getContractsError = (error) => {
  return {
    type: GET_CONTRACTS_ERROR,
    payload: error,
  };
};

export const createContract = (contract) => {
  return {
    type: CREATE_CONTRACT,
    payload: contract,
  };
};

export const createContractSuccess = (contract) => {
  return {
    type: CREATE_CONTRACT_SUCCESS,
    payload: contract,
  };
};

export const createContractError = (error) => {
  return {
    type: CREATE_CONTRACT_ERROR,
    payload: error,
  };
};

export const updateContract = (contract) => {
  return {
    type: UPDATE_CONTRACT,
    payload: contract,
  };
};

export const updateContractSuccess = (contract) => {
  return {
    type: UPDATE_CONTRACT_SUCCESS,
    payload: contract,
  };
};

export const updateContractError = (error) => {
  return {
    type: UPDATE_CONTRACT_ERROR,
    payload: error,
  };
};

export const changeContractStatus = (contractId, status) => {
  return {
    type: CHANGE_CONTRACT_STATUS,
    payload: { contractId, status },
  };
};

export const changeContractStatusSuccess = (contractId, status) => {
  return {
    type: CHANGE_CONTRACT_STATUS_SUCCESS,
    payload: { contractId, status },
  };
};

export const changeContractStatusError = (error) => {
  return {
    type: CHANGE_CONTRACT_STATUS_ERROR,
    payload: error,
  };
};
