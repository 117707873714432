import { NotificationManager } from "../../components/common/react-notifications";
import {
  GET_VOICE_SETTINGS,
  GET_VOICE_SETTINGS_ERROR,
  GET_VOICE_SETTINGS_SUCCESS,
  UPDATE_VOICE_SETTINGS,
} from "../actions";

const INIT_STATE = {
  data: {},
  sharedVoiceSettings: [],
  error: "",
  isLoading: true,
};

export default (state = INIT_STATE, action) => {
  let newState = {};
  switch (action.type) {
    case GET_VOICE_SETTINGS:
      return { ...state, isLoading: true };

    case GET_VOICE_SETTINGS_SUCCESS:
      newState = { ...state, isLoading: false };
      newState.data[action.payload.voiceSettings.id] = action.payload.voiceSettings;
      newState.sharedVoiceSettings = action.payload.sharedVoiceSettings;

      return newState;

    case GET_VOICE_SETTINGS_ERROR:
      NotificationManager.error(
        "Ocorreu um erro ao buscar as configurações de voz",
        "Erro",
        5000,
        () => {},
        null,
        "filled rounded-small",
      );
      return { ...state, isLoading: false, error: action.payload };

    case UPDATE_VOICE_SETTINGS:
      newState = { ...state, isLoading: true };
      return newState;

    default:
      return { ...state };
  }
};
