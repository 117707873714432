import {
  ADD_WORKFLOW_SUBMISSION,
  GET_SUBMISSIONS_BY_USER_LEVEL,
  GET_SUBMISSIONS_BY_USER_LEVEL_ERROR,
  GET_SUBMISSIONS_BY_USER_LEVEL_SUCCESS,
  PROCESS_PENDING_SUBMISSIONS,
  UPDATE_WORKFLOW_SUBMISSION,
  WORKFLOW_CLEAN_CURRENT_SUBMISSION,
  WORKFLOW_GET_SUBMISSION,
  WORKFLOW_GET_SUBMISSION_ERROR,
  WORKFLOW_GET_SUBMISSION_SUCCESS,
  WORKFLOW_UPDATE_CURRENT_SUBMISSION,
} from "../actions";

export const getSubmissionsByUserLevel = (userLevel) => ({
  type: GET_SUBMISSIONS_BY_USER_LEVEL,
  payload: userLevel,
});

export const processPendingSubmissions = () => ({
  type: PROCESS_PENDING_SUBMISSIONS,
  payload: {},
});

export const getSubmissionsByUserLevelSuccess = (submissions) => ({
  type: GET_SUBMISSIONS_BY_USER_LEVEL_SUCCESS,
  payload: { submissions },
});

export const getSubmissionsByUserLevelError = (error) => ({
  type: GET_SUBMISSIONS_BY_USER_LEVEL_ERROR,
  payload: error,
});

export const addWorkflowSubmission = (allSubmissions, submission) => ({
  type: ADD_WORKFLOW_SUBMISSION,
  payload: { allSubmissions, submission },
});

export const updateWorkflowSubmission = (allSubmissions, submission) => ({
  type: UPDATE_WORKFLOW_SUBMISSION,
  payload: { allSubmissions, submission },
});

export const getWorkflowSubmission = (submissionId) => ({
  type: WORKFLOW_GET_SUBMISSION,
  payload: submissionId,
});

export const getWorkflowSubmissionSuccess = (submission) => ({
  type: WORKFLOW_GET_SUBMISSION_SUCCESS,
  payload: { submission },
});

export const getWorkflowSubmissionError = (error) => ({
  type: WORKFLOW_GET_SUBMISSION_ERROR,
  payload: error,
});

export const cleanCurrentSubmission = () => ({
  type: WORKFLOW_CLEAN_CURRENT_SUBMISSION,
  payload: {},
});

export const updateCurrentSubmission = (submission) => ({
  type: WORKFLOW_UPDATE_CURRENT_SUBMISSION,
  payload: submission,
});
