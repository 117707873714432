import { combineReducers } from "redux";

import aiSuggestions from "./aiSuggestions/reducer";
import appSettings from "./appSettings/reducer";
import authUser from "./auth/reducer";
import committees from "./committees/reducer";
import dossier from "./dossier/reducer";
import contracts from "./contracts/reducer";
import exportData from "./exportData/reducer";
import files from "./files/reducer";
import formPageSettings from "./formPageSettings/reducer";
import formSettings from "./formSettings/reducer";
import homePageSettings from "./homePageSettings/reducer";
import marketingAssets from "./marketingAssets/reducer";
import menu from "./menu/reducer";
import orgs from "./org/reducer";
import settings from "./settings/reducer";
import statusPageSettings from "./statusPageSettings/reducer";
import users from "./user/reducer";
import voiceSettings from "./voiceSettings/reducer";
import whatsappSettings from "./whatsappSettings/reducer";
import workflowEventTab from "./workflow/eventTab/reducer";
import workflow from "./workflow/reducer";
import workflow2 from "./workflow2/reducer";

const reducers = combineReducers({
  menu,
  settings,
  authUser,
  orgs,
  contracts,
  users,
  workflow,
  workflowEventTab,
  voiceSettings,
  formSettings,
  whatsappSettings,
  workflow2,
  marketingAssets,
  homePageSettings,
  statusPageSettings,
  formPageSettings,
  appSettings,
  files,
  aiSuggestions,
  committees,
  dossier,
  exportData,
});

export default reducers;
