// import { NotificationManager } from "../../components/common/react-notifications";

import {
  ADD_WORKFLOW_SUBMISSION,
  GET_SUBMISSIONS_BY_USER_LEVEL,
  GET_SUBMISSIONS_BY_USER_LEVEL_ERROR,
  GET_SUBMISSIONS_BY_USER_LEVEL_SUCCESS,
  PROCESS_PENDING_SUBMISSIONS,
  UPDATE_WORKFLOW_SUBMISSION,
  WORKFLOW_CLEAN_CURRENT_SUBMISSION,
  WORKFLOW_GET_SUBMISSION,
  WORKFLOW_GET_SUBMISSION_ERROR,
  WORKFLOW_GET_SUBMISSION_SUCCESS,
  WORKFLOW_UPDATE_CURRENT_SUBMISSION,
} from "../actions";

const INIT_STATE = {
  submissions: {},
  currentSubmission: {
    org: {},
    wbData: {},
    eventData: {},
  },
  error: "",
  isLoading: false,
};

export default (state = INIT_STATE, action) => {
  let newState = {};
  switch (action.type) {
    case WORKFLOW_CLEAN_CURRENT_SUBMISSION:
      newState = { ...state, isLoading: false };
      newState.currentSubmission = {
        org: {},
        wbData: {},
        eventData: {},
      };
      return newState;

    case WORKFLOW_UPDATE_CURRENT_SUBMISSION:
      newState = { ...state, isLoading: false };
      newState.currentSubmission = {
        ...newState.currentSubmission,
        ...action.payload,
      };
      return newState;

    case GET_SUBMISSIONS_BY_USER_LEVEL:
    case WORKFLOW_GET_SUBMISSION:
      return { ...state, isLoading: true };

    case GET_SUBMISSIONS_BY_USER_LEVEL_SUCCESS:
      newState = { ...state, isLoading: false };
      newState.submissions = action.payload.submissions;

      return newState;

    case WORKFLOW_GET_SUBMISSION_SUCCESS:
      newState = { ...state, isLoading: false };
      newState.currentSubmission = action.payload.submission;
      return newState;

    case GET_SUBMISSIONS_BY_USER_LEVEL_ERROR:
    case WORKFLOW_GET_SUBMISSION_ERROR:
      return { ...state, isLoading: true, error: action.payload };

    case ADD_WORKFLOW_SUBMISSION:
    case UPDATE_WORKFLOW_SUBMISSION:
      // TODO fix local state
      return { ...state, isLoading: true };

    case PROCESS_PENDING_SUBMISSIONS:
    default:
      return { ...state };
  }
};
