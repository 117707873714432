import {
  GET_STATUS_PAGE_SETTINGS,
  GET_STATUS_PAGE_SETTINGS_ERROR,
  GET_STATUS_PAGE_SETTINGS_SUCCESS,
  SET_STATUS_PAGE_SETTINGS,
} from "../actions";

export const getStatusPageSettings = (orgId, lang) => ({
  type: GET_STATUS_PAGE_SETTINGS,
  payload: { orgId, lang },
});

export const getStatusPageSettingsSuccess = (orgId, statusPageSettings) => ({
  type: GET_STATUS_PAGE_SETTINGS_SUCCESS,
  payload: { orgId, statusPageSettings },
});

export const getStatusPageSettingsError = (error) => ({
  type: GET_STATUS_PAGE_SETTINGS_ERROR,
  payload: error,
});

export const setStatusPageSettings = (orgId, statusPageSettingsValues, lang) => ({
  type: SET_STATUS_PAGE_SETTINGS,
  payload: { orgId, statusPageSettingsValues, lang },
});
