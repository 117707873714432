import { HomePageSettings } from "@ouvidor-digital/models";
import Services from "@ouvidor-digital/persistence-services";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import { db } from "../../helpers/Firebase";
import { GET_HOME_PAGE_SETTINGS, SET_HOME_PAGE_SETTINGS } from "../actions";
import { addOrgMediaAsync } from "../services/MediaService";

import { getHomePageSettingsError, getHomePageSettingsSuccess } from "./actions";

const { HomePageSettingsService, OrgService } = Services;

const homePageSettingsService = new HomePageSettingsService(db);
const orgService = new OrgService(db);

const toModel = (values) => {
  const newHomePageSettings = new HomePageSettings(
    values.id,
    values.orgId,
    values.orgName,
    values.fontFamily,
    values.actionPrimaryColor,
    values.actionSecondaryColor,
    values.fontColor,
    values.logoUrl,
    values.menu,
    values.bannerODBackgroundColor,
    values.bannerODFontColor,
    values.showBannerOD,
    values.createdDate,
    new Date(),
    values.templateId,
    // Object.fromEntries(values.templateSettings),
    {
      chave: "valor",
    },
    values.primarySectionTitle,
    values.primarySectionDescription,
    values.primarySectionBackgroundUrl,
    values.primarySectionFontColor,
    values.primarySectionButtons,
    values.showMultipleOrgsSection,
    values.multipleOrgsSectionTitle,
    values.multipleOrgsLogo,
    values.showMultipleOrgsHeaderLogo,
    values.secondarySectionTitle,
    values.secondarySectionDescription,
    values.secondarySectionFontColor,
    values.secondarySectionBackgroundColor,
    values.secondarySectionButtons,
    values.secondarySectionBackgroundUrl,
  );

  return newHomePageSettings;
};

async function getHomePageIdAsync(orgId, lang) {
  const org = await orgService.getByIdAsync(orgId);

  const defaultLanguageValue = org.defaultLanguage.value;

  if (lang !== defaultLanguageValue) {
    return `default-${lang}`;
  }

  return "default";
}

function* loadHomePageSettings({ payload }) {
  const { orgId, lang } = payload;
  try {
    const homePageId = yield call(getHomePageIdAsync, orgId, lang);
    const homePageSettings = yield call(homePageSettingsService.getByIdAsync, homePageId, orgId);

    // there is a bug, when `secondarySectionButtons` does not exist in the database, we need to set a default value.
    if (homePageSettings && !homePageSettings.secondarySectionButtons) {
      homePageSettings.secondarySectionButtons = [...homePageSettings.primarySectionButtons];
    }

    yield put(getHomePageSettingsSuccess(orgId, homePageSettings));
  } catch (error) {
    console.error(error);
    yield put(getHomePageSettingsError(error));
  }
}

function* setHomePageSettings({ payload }) {
  try {
    const { orgId, homePageSettings, lang } = payload;

    if (orgId && homePageSettings) {
      let currentLogoUrl = homePageSettings.logoUrl;
      let currentPrimarySectionBackgroundUrl = homePageSettings.primarySectionBackgroundUrl;
      let currentSecondarySectionBackgroundUrl = homePageSettings.secondarySectionBackgroundUrl;

      // add logo
      if (homePageSettings.logo instanceof File) {
        currentLogoUrl = yield call(addOrgMediaAsync, homePageSettings.logo, `${orgId}/public`, "logo", {});
      }

      // add primarySectionBackground
      if (homePageSettings.primarySectionBackground instanceof File) {
        currentPrimarySectionBackgroundUrl = yield call(
          addOrgMediaAsync,
          homePageSettings.primarySectionBackground,
          `${orgId}/public`,
          "primarySectionBackground",
          {},
        );
      }

      // add secondarySectionBackground
      if (homePageSettings.secondarySectionBackground instanceof File) {
        currentSecondarySectionBackgroundUrl = yield call(
          addOrgMediaAsync,
          homePageSettings.secondarySectionBackground,
          `${orgId}/public`,
          "secondarySectionBackground",
          {},
        );
      }

      const multipleOrgsLogo = [];
      for (let i = 0; i < homePageSettings.multipleOrgsLogo.length; i++) {
        const logo = homePageSettings.multipleOrgsLogo[i];
        if (logo.file instanceof File) {
          // upload file and get public url
          const logoUrl = yield call(
            addOrgMediaAsync,
            logo.file,
            `${orgId}/public/multipleOrgsLogo`,
            `multipleLogo-${i}`,
            {},
          );
          multipleOrgsLogo.push({ url: logoUrl, href: logo.href });
        } else if (logo.url !== "") {
          // images that have already been uploaded and saved
          multipleOrgsLogo.push({ url: logo.url, href: logo.href });
        }
      }

      const homePageId = yield call(getHomePageIdAsync, orgId, lang);

      const homePageSettingsModel = toModel({
        ...homePageSettings,
        multipleOrgsLogo: multipleOrgsLogo,
        logoUrl: currentLogoUrl,
        primarySectionBackgroundUrl: currentPrimarySectionBackgroundUrl,
        secondarySectionBackgroundUrl: currentSecondarySectionBackgroundUrl,
        id: homePageId,
      });

      yield call(homePageSettingsService.addAsync, orgId, homePageSettingsModel);

      yield put(getHomePageSettingsSuccess(orgId, homePageSettingsModel));
    } else {
      console.error("Não é possível salvar as configurações da página wb-portal home");
      yield put(getHomePageSettingsError("Não é possível salvar as configurações da página wb-portal home"));
    }
  } catch (error) {
    console.error(error);
    yield put(getHomePageSettingsError(error));
  }
}

export function* watchGetHomePageSettings() {
  yield takeEvery(GET_HOME_PAGE_SETTINGS, loadHomePageSettings);
}

export function* watchSetHomePageSettings() {
  yield takeEvery(SET_HOME_PAGE_SETTINGS, setHomePageSettings);
}

export default function* rootSaga() {
  yield all([fork(watchGetHomePageSettings), fork(watchSetHomePageSettings)]);
}
