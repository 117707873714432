import {
  GET_APP_SETTINGS,
  GET_APP_SETTINGS_ERROR,
  GET_APP_SETTINGS_SUCCESS,
  GET_PROPRIETARY_COURSES_SETTINGS,
  GET_PROPRIETARY_COURSES_SETTINGS_ERROR,
  GET_PROPRIETARY_COURSES_SETTINGS_SUCCESS,
  SET_APP_SETTINGS,
  SET_PROPRIETARY_COURSES_SETTINGS,
  SET_PROPRIETARY_COURSES_SETTINGS_ERROR,
  SET_PROPRIETARY_COURSES_SETTINGS_SUCCESS,
} from "../actions";

const INIT_STATE = {
  orgs: {},
  proprietaryCoursesSettings: {},
  error: "",
  isLoading: true,
};

export default (state = INIT_STATE, action) => {
  let newState = {};
  switch (action.type) {
    case SET_APP_SETTINGS:
    case GET_APP_SETTINGS:
      return { ...state, isLoading: true };

    case GET_APP_SETTINGS_SUCCESS:
      newState = { ...state, isLoading: false };
      newState.orgs[action.payload.orgId] = { ...action.payload.appSettings };

      return newState;
    case GET_APP_SETTINGS_ERROR:
      return { ...state, isLoading: false, error: action.payload };

    case GET_PROPRIETARY_COURSES_SETTINGS:
      return { ...state, isLoading: true };

    case GET_PROPRIETARY_COURSES_SETTINGS_SUCCESS:
      newState = { ...state, isLoading: false };
      newState.proprietaryCoursesSettings[action.payload.orgId] = { ...action.payload.proprietaryCoursesSettings };

      return newState;
    case GET_PROPRIETARY_COURSES_SETTINGS_ERROR:
      return { ...state, isLoading: false, error: action.payload };

    case SET_PROPRIETARY_COURSES_SETTINGS:
      return { ...state, isLoading: true };

    case SET_PROPRIETARY_COURSES_SETTINGS_SUCCESS:
      newState = { ...state, isLoading: false };
      newState.proprietaryCoursesSettings[action.payload.orgId] = { ...action.payload.proprietaryCoursesSettings };

      return newState;

    case SET_PROPRIETARY_COURSES_SETTINGS_ERROR:
      return { ...state, isLoading: false, error: action.payload };

    default:
      return { ...state };
  }
};
