import { FormQuestionDatasource } from "@ouvidor-digital/constant";
import { FormQuestion, FormSettings, Intl, WebSettings } from "@ouvidor-digital/models";
import Services from "@ouvidor-digital/persistence-services";
import { createUUID } from "@ouvidor-digital/utils";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import { db, perf } from "../../helpers/Firebase";
import {
  GET_FORM_QUESTIONS_SETTINGS,
  GET_FORM_SETTINGS,
  UPDATE_FORM_QUESTIONS_SETTINGS,
  UPDATE_FORM_SETTINGS,
} from "../actions";

import {
  getFormQuestionsSettingsError,
  getFormQuestionsSettingsSuccess,
  getFormSettingsError,
  getFormSettingsSuccess,
  updateFormQuestionsSettingsError,
  updateFormQuestionsSettingsSuccess,
  updateFormSettingsError,
  updateFormSettingsSuccess,
} from "./actions";

const { FormSettingsService, FormQuestionsService, WebSettingsService, OrgService } = Services;

const formSettingsService = new FormSettingsService(db, perf);
const formQuestionsService = new FormQuestionsService(db, perf);
const webSettingsService = new WebSettingsService(db, perf);
const orgService = new OrgService(db, perf);

async function getFormIdAsync(orgId, lang) {
  const org = await orgService.getByIdAsync(orgId);

  if (org.defaultLanguage.value && org.defaultLanguage.value !== lang) {
    return `default-${lang}`;
  }

  return "default";
}

function* loadFormSettings({ payload }) {
  const { orgId, id: formId } = payload;

  try {
    let formSettings = yield call(formSettingsService.getByIdAsync, formId, orgId);

    if (!formSettings) {
      formSettings = new FormSettings(formId, orgId, "", "", "", "", "", "", "", "", "", new Date(), new Date());
    }

    yield put(getFormSettingsSuccess(formSettings, formId, orgId));
  } catch (error) {
    console.error(error);
    yield put(getFormSettingsError(error));
  }
}

const getDefaultQuestions = (formId, org, lang) => {
  const orgData = {
    orgName: org.name,
    logoURL: "",
    webCode: org.webCode,
    whatsAppCode: org.whatsappNumber,
    orgType: org.type,
    orgPlan: org.plan,
  };
  return Intl.getDefaultFormValues(orgData, formId, lang);
};

function* loadFormQuestionsSettings({ payload }) {
  const { org, lang, templateLanguage } = payload;

  const orgId = org.id;

  try {
    const formId = yield call(getFormIdAsync, orgId, lang);
    const questionsSettings = yield call(formQuestionsService.getAllAsync, orgId, formId);

    if (questionsSettings.length === 0 && org) {
      const defaultQuestions = getDefaultQuestions(formId, org, templateLanguage);

      yield put(getFormQuestionsSettingsSuccess(defaultQuestions, formId, orgId, false));
      return;
    }

    yield put(getFormQuestionsSettingsSuccess(questionsSettings, formId, orgId, true));
  } catch (error) {
    console.error(error);
    yield put(getFormQuestionsSettingsError(error));
  }
}

function* updateFormSettings({ payload }) {
  try {
    const { formSettings, org, id: formId } = payload;

    // Create models to update
    const returnUrl = "https://ouvidordigital.com.br/" + org.webCode || "";
    const formSettingsModel = new FormSettings(
      formId,
      org.id,
      org.name,
      formSettings.title,
      returnUrl,
      formSettings.logoUrl,
      formSettings.disclaimerMessage,
      formSettings.protocolMessage,
      formSettings.successMessage,
      formSettings.errorMessage,
      formSettings.color,
      new Date(),
      new Date(),
    );

    const webSettingsModel = new WebSettings(
      org.webCode,
      org.id,
      org.name,
      formSettings.title,
      formSettings.logoUrl,
      formSettings.color,
      new Date(),
      new Date(),
    );

    // Check if webCode can be used
    const webSettings = yield call(webSettingsService.getByIdAsync, org.webCode);
    if (webSettings && webSettings.orgId !== org.id) {
      yield put(updateFormSettingsError("Invalid Webcode"));
      return;
    }

    yield call(formSettingsService.updateAsync, formSettingsModel);
    yield call(webSettingsService.addAsync, webSettingsModel);

    yield put(updateFormSettingsSuccess(formSettings, org.id, formId));
  } catch (error) {
    console.error(error);
    yield put(updateFormSettingsError(error));
  }
}

function* updateFormQuestionsSettings({ payload }) {
  try {
    const { questionsSettings, org, lang } = payload;

    const formId = yield call(getFormIdAsync, org.id, lang);

    // get all questions to analise what must be deleted
    const currentQuestions = yield call(formQuestionsService.getAllAsync, org.id, formId);

    if (currentQuestions.length === 0 && questionsSettings.length > 0) {
      for (const q of questionsSettings) {
        yield call(formQuestionsService.addAsync, q, org.id);
      }
    }

    for (const cQ of currentQuestions) {
      // if has been deleted
      if (
        questionsSettings.findIndex((q) => {
          return cQ.id === q.id;
        }) === -1
      ) {
        yield call(formQuestionsService.deleteAsync, org.id, formId, cQ.id);
      }
    }

    // order Questions changing the order property
    for (let i = 0; i < questionsSettings.length; i++) {
      const q = questionsSettings[i];

      if (q.isChanged) {
        // reset propriety
        q.isChanged = undefined;

        let value = q.value;
        if (q.datasource && q.datasource !== FormQuestionDatasource.NONE) {
          value = {
            datasource: q.datasource,
          };
        }

        const questionsSettingsModel = new FormQuestion(
          q.newId || q.id || createUUID(),
          formId,
          i,
          q.title,
          q.subtitle,
          q.altMessage,
          q.hintMessage,
          q.type,
          q.section,
          value,
          q.pattern,
          q.viewRules,
          q.isRequired,
          q.columns,
          q.createdDate,
          new Date(),
        );

        if (q.newId) {
          yield call(formQuestionsService.deleteAsync, org.id, formId, q.id);
        }

        yield call(formQuestionsService.updateAsync, questionsSettingsModel, org.id);
      }
    }

    yield put(updateFormQuestionsSettingsSuccess(questionsSettings, org.id, formId));
  } catch (error) {
    console.error(error);
    yield put(updateFormQuestionsSettingsError(error));
  }
}

export function* watchGetFormSettings() {
  yield takeEvery(GET_FORM_SETTINGS, loadFormSettings);
}

export function* watchGetFormQuestionsSettings() {
  yield takeEvery(GET_FORM_QUESTIONS_SETTINGS, loadFormQuestionsSettings);
}

export function* watchUpdateFormSettings() {
  yield takeEvery(UPDATE_FORM_SETTINGS, updateFormSettings);
}

export function* watchUpdateFormQuestionsSettings() {
  yield takeEvery(UPDATE_FORM_QUESTIONS_SETTINGS, updateFormQuestionsSettings);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetFormSettings),
    fork(watchGetFormQuestionsSettings),
    fork(watchUpdateFormSettings),
    fork(watchUpdateFormQuestionsSettings),
  ]);
}
