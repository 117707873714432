import {
  GET_FILE_DATA,
  GET_FILE_DATA_ERROR,
  GET_FILE_DATA_SUCCESS,
  UPLOAD_FILE,
  UPLOAD_FILE_ERROR,
  UPLOAD_FILE_SUCCESS,
} from "../actions";

const INIT_STATE = {
  orgs: {},
  error: "",
  isLoading: true,
};

export default (state = INIT_STATE, action) => {
  let newState = {};
  switch (action.type) {
    case GET_FILE_DATA:
    case UPLOAD_FILE:
      newState = { ...state, isLoading: true };
      return newState;

    case GET_FILE_DATA_SUCCESS:
    case GET_FILE_DATA_ERROR:
    case UPLOAD_FILE_SUCCESS:
      newState = { ...state, isLoading: false };
      newState.files[action.payload.orgId] = {
        [action.payload.path]: {
          [action.payload.fileName]: action.payload.content,
        },
      };
      return newState;
    case UPLOAD_FILE_ERROR:
    default:
      return { ...state };
  }
};
