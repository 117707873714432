import {
  CHANGE_CONTRACT_STATUS,
  CHANGE_CONTRACT_STATUS_ERROR,
  CHANGE_CONTRACT_STATUS_SUCCESS,
  CREATE_CONTRACT,
  CREATE_CONTRACT_ERROR,
  CREATE_CONTRACT_SUCCESS,
  GET_CONTRACTS,
  GET_CONTRACTS_ERROR,
  GET_CONTRACTS_SUCCESS,
  UPDATE_CONTRACT,
  UPDATE_CONTRACT_ERROR,
  UPDATE_CONTRACT_SUCCESS,
} from "../actions";

const initialState = {
  list: [],
  loading: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CONTRACTS:
      return {
        ...state,
        loading: true,
      };

    case GET_CONTRACTS_SUCCESS:
      return {
        ...state,
        list: action.payload,
        loading: false,
      };

    case GET_CONTRACTS_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case CREATE_CONTRACT:
      return {
        ...state,
        loading: true,
      };

    case UPDATE_CONTRACT:
      return {
        ...state,
        isUpdating: true,
      };

    case CREATE_CONTRACT_SUCCESS:
      return {
        ...state,
        list: [...state.list, action.payload],
        loading: false,
      };

    case UPDATE_CONTRACT_SUCCESS:
      return {
        ...state,
        list: state.list.map((contract) => {
          if (contract.id === action.payload.id) {
            return action.payload;
          }
          return contract;
        }),
        isUpdating: false,
      };

    case CREATE_CONTRACT_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case UPDATE_CONTRACT_ERROR:
      return {
        ...state,
        error: action.payload,
        isUpdating: false,
      };

    case CHANGE_CONTRACT_STATUS:
      return {
        ...state,
        loading: true,
      };

    case CHANGE_CONTRACT_STATUS_SUCCESS:
      return {
        ...state,
        list: state.list.map((contract) => {
          if (contract.id === action.payload.contractId) {
            contract.status = action.payload.status;
          }
          return contract;
        }),
        loading: false,
      };

    case CHANGE_CONTRACT_STATUS_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    default:
      return state;
  }
};
