import {
  GET_DOSSIE_FORM_ERROR,
  GET_DOSSIE_FORM_REQUEST,
  GET_DOSSIE_FORM_SUCCESS,
  GET_DOSSIERS,
  GET_DOSSIERS_ERROR,
  GET_DOSSIERS_SUCCESS,
  SAVE_DOSSIE_FORM_ERROR,
  SAVE_DOSSIE_FORM_REQUEST,
  SAVE_DOSSIE_FORM_SUCCESS,
  SEND_DOSSIER_TO_ORG,
  SEND_DOSSIER_TO_ORG_ERROR,
  SEND_DOSSIER_TO_ORG_SUCCESS,
} from "../actions";

export const saveDossieFormRequest = (formData) => ({
  type: SAVE_DOSSIE_FORM_REQUEST,
  payload: formData,
});

export const saveDossieFormSuccess = (data) => ({
  type: SAVE_DOSSIE_FORM_SUCCESS,
  payload: data,
});

export const saveDossieFormFailure = (error) => ({
  type: SAVE_DOSSIE_FORM_ERROR,
  payload: error,
});

export const getDossieFormRequest = (orgId) => ({
  type: GET_DOSSIE_FORM_REQUEST,
  payload: orgId,
});

export const getDossieFormSuccess = (data) => ({
  type: GET_DOSSIE_FORM_SUCCESS,
  payload: data,
});

export const getDossieFormFailure = (error) => ({
  type: GET_DOSSIE_FORM_ERROR,
  payload: error,
});

export const getDossiers = () => ({
  type: GET_DOSSIERS,
});

export const getDossiersSuccess = (requestDossiers) => ({
  type: GET_DOSSIERS_SUCCESS,
  payload: requestDossiers,
});

export const getDossiersError = (error) => ({
  type: GET_DOSSIERS_ERROR,
  payload: error,
});

export const sendDossierToOrg = (dossierId, orgId, file, history) => ({
  type: SEND_DOSSIER_TO_ORG,
  payload: { dossierId, orgId, file, history },
});

export const sendDossierToOrgSuccess = (dossiers) => ({
  type: SEND_DOSSIER_TO_ORG_SUCCESS,
  payload: dossiers,
});

export const sendDossierToOrgError = (error) => ({
  type: SEND_DOSSIER_TO_ORG_ERROR,
  payload: error,
});
