import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import { functions } from "../../helpers/Firebase";
import {
  GENERATE_AI_SUGGESTION,
  generateAiSuggestionError,
  generateAiSuggestionSuccess,
  GET_AI_SUGGESTION,
  getAiSuggestionError,
  getAiSuggestionSuccess,
  UPDATE_SUBMISSION_SUGGESTION,
} from "../actions";
import { _buildSubmission } from "../workflow2/saga";

const getSubmissionPreTreatmentFunction = functions.httpsCallable("getSubmissionSuggestion");
const updateSubmissionSuggestionFunction = functions.httpsCallable("updateSubmissionSuggestion");
const generateSubmissionSuggestionFunction = functions.httpsCallable("aiGenerateSubmissionSuggestions");

function* getAiSuggestion(action) {
  const { submissionId } = action.payload;

  try {
    const aiSuggestions = yield call(getSubmissionPreTreatmentFunction, {
      featureId: submissionId,
    });

    const { preTreatmentSuggestions } = aiSuggestions.data;

    yield put(getAiSuggestionSuccess(submissionId, preTreatmentSuggestions));
  } catch (error) {
    console.error(error);
    yield put(getAiSuggestionError(error));
  }
}

function* updateSubmissionSuggestion(action) {
  const { submission, feedbackValue } = action.payload;

  try {
    const submissionModel = _buildSubmission(submission);

    yield call(updateSubmissionSuggestionFunction, {
      featureId: submissionModel.id,
      finalSubmissionValue: submissionModel,
      feedbackValue,
    });

    yield put(getAiSuggestionSuccess());
  } catch (error) {
    console.error(error);
    yield put(getAiSuggestionError(error));
  }
}

function* generateAiSuggestion(action) {
  const { submission } = action.payload;

  const requestSubmission = { ...submission, event: submission.eventData, extras: submission.eventData.extras };

  try {
    yield call(generateSubmissionSuggestionFunction, {
      submission: requestSubmission,
    });

    const aiSuggestions = yield call(getSubmissionPreTreatmentFunction, {
      featureId: submission.id,
    });

    const { preTreatmentSuggestions } = aiSuggestions.data;

    yield put(generateAiSuggestionSuccess(submission.id, preTreatmentSuggestions));
  } catch (error) {
    console.error(error);
    yield put(generateAiSuggestionError(error));
  }
}

export function* watchGetAiSuggestions() {
  yield takeEvery(GET_AI_SUGGESTION, getAiSuggestion);
  yield takeEvery(UPDATE_SUBMISSION_SUGGESTION, updateSubmissionSuggestion);
  yield takeEvery(GENERATE_AI_SUGGESTION, generateAiSuggestion);
}

export default function* rootSaga() {
  yield all([fork(watchGetAiSuggestions)]);
}
