import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import { NotificationManager } from "../../components/common/react-notifications";
import { functions } from "../../helpers/Firebase";
import { intlMessages } from "../../helpers/IntlMessages";
import { CREATE_COMMITTEE, DELETE_COMMITTEE, GET_COMMITTEES, UPDATE_COMMITTEE } from "../actions";

import {
  createCommitteeError,
  createCommitteeSuccess,
  deleteCommitteeError,
  deleteCommitteeSuccess,
  getCommitteesError,
  getCommitteesSuccess,
  updateCommitteeError,
  updateCommitteeSuccess,
} from "./actions";

function* createCommittee(action) {
  const createCommitteeFunction = functions.httpsCallable("createCommittee");

  try {
    const responseCreateCommittee = yield call(createCommitteeFunction, {
      ...action.payload,
    });

    NotificationManager.secondary(
      intlMessages["info.save"],
      intlMessages["general.done"],
      5000,
      () => {},
      null,
      "filled rounded-small",
    );

    const createdCommittee = responseCreateCommittee.data;
    yield put(createCommitteeSuccess({ committee: createdCommittee }));
  } catch (error) {
    const e = error.message;
    if (e === "Error: One member must not be inside the committee.") {
      NotificationManager.error(
        "Um membro deve ficar fora do comitê, para receber todos os relatos.",
        intlMessages["general.error"],
        5000,
        () => {},
        null,
        "filled rounded-small",
      );
      yield put(createCommitteeError({ error }));
      return;
    }

    console.error(error);
    NotificationManager.error(
      intlMessages["ai-analyze-error.message"],
      intlMessages["general.error"],
      5000,
      () => {},
      null,
      "filled rounded-small",
    );
    yield put(createCommitteeError({ error }));
  }
}

function* deleteCommittee(action) {
  const deleteCommitteeFunction = functions.httpsCallable("deleteCommittee");

  try {
    const { committeeId, orgId } = action.payload;
    yield call(deleteCommitteeFunction, {
      ...action.payload,
    });

    NotificationManager.secondary(
      intlMessages["info.save"],
      intlMessages["general.done"],
      5000,
      () => {},
      null,
      "filled rounded-small",
    );

    yield put(deleteCommitteeSuccess({ committeeId, orgId }));
  } catch (error) {
    console.error(error);
    NotificationManager.error(
      intlMessages["ai-analyze-error.message"],
      intlMessages["general.error"],
      5000,
      () => {},
      null,
      "filled rounded-small",
    );
    yield put(deleteCommitteeError({ error }));
  }
}

function* getCommittees(action) {
  const getCommitteesFunction = functions.httpsCallable("getAllCommittees");

  const orgId = action.payload.orgId;

  try {
    const allCommitteesResponse = yield call(getCommitteesFunction, {
      ...action.payload,
    });

    const allCommittees = allCommitteesResponse;

    yield put(
      getCommitteesSuccess({
        committees: allCommittees.data,
        orgId: orgId,
      }),
    );
  } catch (error) {
    console.error(error);
    NotificationManager.error(
      intlMessages["ai-analyze-error.message"],
      intlMessages["general.error"],
      5000,
      () => {},
      null,
      "filled rounded-small",
    );
    yield put(getCommitteesError({ error }));
  }
}

function* updateCommittee(action) {
  const updateCommitteeFunction = functions.httpsCallable("updateCommittee");

  try {
    const responseUpdateCommittee = yield call(updateCommitteeFunction, action.payload);

    const updateCommitteeResponse = responseUpdateCommittee.data;

    NotificationManager.secondary(
      intlMessages["info.save"],
      intlMessages["general.done"],
      5000,
      () => {},
      null,
      "filled rounded-small",
    );

    yield put(updateCommitteeSuccess({ committee: updateCommitteeResponse }));
  } catch (error) {
    console.error(error);
    NotificationManager.error(
      "There was an error updating the committee.",
      "Error",
      5000,
      () => {},
      null,
      "filled rounded-small",
    );
    yield put(updateCommitteeError({ error }));
  }
}

export function* watchCommittees() {
  yield takeEvery(CREATE_COMMITTEE, createCommittee);
  yield takeEvery(DELETE_COMMITTEE, deleteCommittee);
  yield takeEvery(GET_COMMITTEES, getCommittees);
  yield takeEvery(UPDATE_COMMITTEE, updateCommittee);
}

export default function* rootSaga() {
  yield all([fork(watchCommittees)]);
}
