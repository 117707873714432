import Services from "@ouvidor-digital/persistence-services";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import ANALYTICS_ACTIONS from "../../constants/AnalyticsActions";
import { analytics, auth, db, functions, perf } from "../../helpers/Firebase";
import { LOGIN_USER, LOGOUT_USER, RECOVERY_PASSWORD, RESET_PASSWORD, SET_PREFERENCE } from "../actions";
import LocalUserService from "../services/LocalUserService";

// import { NotificationManager } from "../../components/ReactNotifications";
import {
  loginUserError,
  loginUserSuccess,
  recoveryPasswordError,
  recoveryPasswordSuccess,
  resetPasswordError,
  resetPasswordSuccess,
  setPreferenceError,
  setPreferenceSuccess,
} from "./actions";

// import { registerEvent } from "../log/actions";

const localUserService = new LocalUserService(localStorage);
const userService = new Services.UserService(db, auth, functions);
const orgService = new Services.OrgService(db, perf);
const firebaseAnalyticsService = new Services.FirebaseAnalyticsService(analytics);

const loginWithEmailPasswordAsync = async (email, password) =>
  await auth
    .signInWithEmailAndPassword(email, password)
    .then((authUser) => authUser)
    .catch((error) => error);

const validateCaptchaTokenAsync = async (captchaToken, version) => {
  const validateRecaptchaFunction = functions.httpsCallable("validateRecaptcha");
  const data = { captchaToken, version };
  return await validateRecaptchaFunction(data);
};

// const validateIPAddressAsync = async () => {
//   const validateIPAddressFunction = functions.httpsCallable(
//     "validateIPAddress"
//   );
//   const data = {};
//   return await validateIPAddressFunction(data);
// };

function* loginWithEmailPassword({ payload }) {
  const { email, password, captchaToken, version } = payload.user;
  const { history } = payload;
  try {
    if (!captchaToken) {
      yield put(loginUserError({ code: "error.auth/recaptcha-not-pressed" }));
      console.error("login failed :", "reCaptcha not pressed");
      return;
    }

    const validCaptcha = yield call(validateCaptchaTokenAsync, captchaToken, version);
    // const validIPAddress = yield call(validateIPAddressAsync);

    if (!validCaptcha.data) {
      yield put(loginUserError({ code: "error.auth/invalid-recaptcha" }));
      console.error("login failed :", "Invalid reCaptcha");
      return;
    }

    // if (!validIPAddress.data) {
    //   yield put(loginUserError({ code: "auth/invalid-network" }));
    //   console.error("login failed :", "Invalid network");
    //   return;
    // }

    const loginUser = yield call(loginWithEmailPasswordAsync, email, password);
    if (!loginUser.message) {
      const currentUser = yield call(userService.getMeAsync);

      currentUser.profile.email = loginUser.user.email;
      currentUser.profile.uid = loginUser.user.uid;

      // TODO: Fix orgName for multi organizations
      const u = {
        ...currentUser,
        orgName: currentUser.orgName || "Ouvidor Digital",
      };

      yield put(loginUserSuccess(u));
      firebaseAnalyticsService.logEvent(ANALYTICS_ACTIONS.ADMIN_LOGIN);
      localUserService.saveUserOnLocalStorage(u);
      // realtimeService.start(u);

      //   let loginEvent = {
      //     type: LogEventTypes.LOGIN,
      //     orgId: u.orgId,
      //     uid: u.uid,
      //     userEmail: u.email,
      //     userName: u.name,
      //     userLevel: u.level,
      //   };
      //   yield put(registerEvent(loginEvent));

      history.push("/");
    } else {
      // catch throw
      yield put(loginUserError(loginUser));
      console.error("login failed :", loginUser.message);
    }
  } catch (error) {
    // catch throw
    console.error("login error : ", error);
  }
}

function* logout({ payload }) {
  // const user = payload.user;
  const { history } = payload;
  try {
    // let logoutEvent = {
    //   type: LogEventTypes.LOGOUT,
    //   uid: user.uid,
    //   userEmail: user.email,
    //   userName: user.name,
    //   userLevel: user.level,
    //   orgId: user.orgId,
    // };
    // yield put(registerEvent(logoutEvent));
    yield call(async () => await auth.signOut());
    localUserService.removeUserFromLocalStorage();
    firebaseAnalyticsService.logEvent(ANALYTICS_ACTIONS.ADMIN_LOGOUT);
    history.push("/");
  } catch (error) {
    console.error(error);
  }
}

function* recovery({ payload }) {
  //   const history = payload.history;
  const { email } = payload;

  try {
    yield call(async () => await auth.sendPasswordResetEmail(email));
    yield put(
      recoveryPasswordSuccess({
        code: "auth/recovery-password-success",
        email: email,
      }),
    );
  } catch (error) {
    yield put(recoveryPasswordError(error));
    console.error("Recovery password error: ", error);
  }
}

function* resetPassword({ payload }) {
  const { oobCode, password, history } = payload;

  try {
    yield call(async () => {
      await auth.verifyPasswordResetCode(oobCode);
    });

    yield call(async () => {
      await auth.confirmPasswordReset(oobCode, password);
    });

    yield put(resetPasswordSuccess("Password alterado com sucesso"));
    alert("Password alterado com sucesso. Você será redirecionado a página inicial...");
    history.push("/");
  } catch (error) {
    yield put(resetPasswordError(error));
    console.error("Reset password error: ", error);
  }
}

function* setPreference({ payload }) {
  const { preference, user } = payload;

  try {
    const currentUser = localUserService.getUserFromLocalStorage();
    if (!currentUser.preferences || !currentUser.preferences[preference.key]) {
      currentUser.preference = {
        [preference.key]: {
          [preference.channel]: preference.value,
        },
      };
    }

    yield call(userService.updatePreferenceAsync, user.uid, preference.key, preference.channel, preference.value);

    // save on orgUser
    const org = yield call(orgService.getByIdAsync, user.orgId);
    org.users[user.uid].preferences[preference.key][preference.channel] = preference.value;
    yield call(orgService.updateAsync, org);

    yield put(setPreferenceSuccess("Preferência alterada com sucesso", preference));

    // save on local storage
    currentUser.preferences[preference.key][preference.channel] = preference.value;
    localUserService.saveUserOnLocalStorage(currentUser);
  } catch (error) {
    yield put(setPreferenceError(error));
  }
}

export function* watchLoginUser() {
  yield takeEvery(LOGIN_USER, loginWithEmailPassword);
}

export function* watchLogoutUser() {
  yield takeEvery(LOGOUT_USER, logout);
}

export function* watchRecoveryUser() {
  yield takeEvery(RECOVERY_PASSWORD, recovery);
}

export function* watchResetPassword() {
  yield takeEvery(RESET_PASSWORD, resetPassword);
}

export function* watchSetPreference() {
  yield takeEvery(SET_PREFERENCE, setPreference);
}

export default function* rootSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchLogoutUser),
    fork(watchRecoveryUser),
    fork(watchResetPassword),
    fork(watchSetPreference),
  ]);
}
