import {
  GET_MARKETING_ASSETS,
  GET_MARKETING_ASSETS_ERROR,
  GET_MARKETING_ASSETS_SUCCESS,
  SET_MARKETING_ASSETS,
} from "../actions";

const INIT_STATE = {
  orgs: {},
  error: "",
  isLoading: true,
};

export default (state = INIT_STATE, action) => {
  let newState = {};
  switch (action.type) {
    case SET_MARKETING_ASSETS:
    case GET_MARKETING_ASSETS:
      return { ...state, isLoading: true };

    case GET_MARKETING_ASSETS_SUCCESS:
      newState = { ...state, isLoading: false };
      newState.orgs[action.payload.orgId] = action.payload.assets || [];

      return newState;

    case GET_MARKETING_ASSETS_ERROR:
      return { ...state, isLoading: false, error: action.payload };

    default:
      return { ...state };
  }
};
