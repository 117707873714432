import { StatusPageSettings } from "@ouvidor-digital/models";
import Services from "@ouvidor-digital/persistence-services";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import { db } from "../../helpers/Firebase";
import { GET_STATUS_PAGE_SETTINGS, SET_STATUS_PAGE_SETTINGS } from "../actions";

import { getStatusPageSettingsError, getStatusPageSettingsSuccess } from "./actions";

const { StatusPageSettingsService, OrgService } = Services;

const statusPageSettingsService = new StatusPageSettingsService(db);
const orgService = new OrgService(db);

async function getStatusPageIdAsync(orgId, lang) {
  const org = await orgService.getByIdAsync(orgId);

  const defaultLanguageValue = org.defaultLanguage.value;

  if (lang !== defaultLanguageValue) {
    return `status-${lang}`;
  }

  return "status";
}

function toModel(values, orgId) {
  const statusPageSettings = new StatusPageSettings(
    values.id,
    orgId,
    values.titleScreen,
    values.disclaimerScreen,
    values.archivedStatus,
    values.deliveredStatus,
    values.inProgressStatus,
    values.finishedStatus,
    values.notFoundStatus,
    values.colorScreen,
    new Date(),
    new Date(),
  );
  return statusPageSettings;
}

function* loadStatusPageSettings({ payload }) {
  const { orgId, lang } = payload;

  try {
    const statusPageId = yield call(getStatusPageIdAsync, orgId, lang);
    const statusPageSettings = yield call(statusPageSettingsService.getByIdAsync, statusPageId, orgId);

    yield put(getStatusPageSettingsSuccess(orgId, statusPageSettings));
  } catch (error) {
    console.error(error);
    yield put(getStatusPageSettingsError(error));
  }
}

function* setStatusPageSettings({ payload }) {
  try {
    const { orgId, statusPageSettingsValues, lang } = payload;

    const statusPageId = yield call(getStatusPageIdAsync, orgId, lang);

    const statusPageSettings = toModel(
      {
        id: statusPageId,
        ...statusPageSettingsValues,
      },
      orgId,
    );

    if (orgId && statusPageSettings) {
      yield call(statusPageSettingsService.addAsync, orgId, statusPageSettings);

      yield put(getStatusPageSettingsSuccess(orgId, statusPageSettings));
    } else {
      console.error("Não é possível salvar as configurações da página de status");
      yield put(getStatusPageSettingsError("Não é possível salvar as configurações da página de status"));
    }
  } catch (error) {
    console.error(error);
    yield put(getStatusPageSettingsError(error));
  }
}

export function* watchgetStatusPageSettings() {
  yield takeEvery(GET_STATUS_PAGE_SETTINGS, loadStatusPageSettings);
}

export function* watchsetStatusPageSettings() {
  yield takeEvery(SET_STATUS_PAGE_SETTINGS, setStatusPageSettings);
}

export default function* rootSaga() {
  yield all([fork(watchgetStatusPageSettings), fork(watchsetStatusPageSettings)]);
}
