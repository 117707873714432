/* eslint-disable max-len */
import { OrgTypes } from "@ouvidor-digital/constant";

const defaultFormValues = {
  [OrgTypes.DENOUNCE]: {
    DEFAULT_TITLE: "Canal de Denúncias - {{orgName}}",
    DEFAULT_DISCLAIMER_MESSAGE:
      "A veracidade das informações providas é de responsabilidade do relator. Este canal é exclusivo para receber denúncias e não deve ser usado para dúvida, reclamação, solicitação de informação, sugestão ou elogio. Todas as denúncias serão encaminhadas para o <b>Comitê de Ética</b> da empresa <b>{{orgName}}</b>.",
    DEFAULT_LOGO_URL:
      "https://cdn.bitrix24.com/b7016133/landing/dd1/dd1776f11e4cd931ce87b4cceaeaea41/LogoOuvidorDigitalPositivaCorPeq_2x.png",
    DEFAULT_PROTOCOL_MESSAGE:
      "<b>Anote este número</b> para acompanhar o andamento da sua denúncia. Para consultar o status do registro da denúncia basta inserir o número do protocolo no site:",
    DEFAULT_SUCCESS_MESSAGE:
      "Agradecemos a sua iniciativa e confiança. <br /> Lembre-se: o número do protocolo associado ao seu relato é: {{protocolNumber}}",
  },
  [OrgTypes.GDPR]: {
    DEFAULT_TITLE: "Canal de Incidentes LGPD - {{orgName}}",
    DEFAULT_DISCLAIMER_MESSAGE:
      "Este canal é exclusivo para dúvidas ou solicitações relacionadas à Lei Geral de Proteção de Dados (LGPD) Todos os relatos serão encaminhadas para a <b>Data Privacy Office (DPO)</b> da empresa <b>{{orgName}}</b>.",
    DEFAULT_LOGO_URL:
      "https://cdn.bitrix24.com/b7016133/landing/dd1/dd1776f11e4cd931ce87b4cceaeaea41/LogoOuvidorDigitalPositivaCorPeq_2x.png",
    DEFAULT_PROTOCOL_MESSAGE:
      "<b>Anote este número</b> para acompanhar o andamento de seu relato. Para consultar o status do relato basta inserir o número do protocolo no site:",
    DEFAULT_SUCCESS_MESSAGE:
      "Agradecemos a sua iniciativa e confiança. <br /> Lembre-se: o número do protocolo associado ao seu relato é: {{protocolNumber}}",
  },
  [OrgTypes.CONTACT_US]: {
    DEFAULT_TITLE: "Canal de Relacionamento da {{orgName}}",
    DEFAULT_DISCLAIMER_MESSAGE:
      "Este canal é exclusivo para dúvidas, reclamações, sugestões ou elogios. Todos os relatos serão encaminhadas para a <b>Equipe de Ouvidoria</b> da empresa <b>{{orgName}}</b>.",
    DEFAULT_LOGO_URL:
      "https://cdn.bitrix24.com/b7016133/landing/dd1/dd1776f11e4cd931ce87b4cceaeaea41/LogoOuvidorDigitalPositivaCorPeq_2x.png",
    DEFAULT_PROTOCOL_MESSAGE:
      "<b>Anote este número</b> para acompanhar o andamento de seu relato. Para consultar o status do relato basta inserir o número do protocolo no site:",
    DEFAULT_SUCCESS_MESSAGE:
      "Agradecemos a sua iniciativa e confiança. <br /> Lembre-se: o número do protocolo associado ao seu relato é: {{protocolNumber}}",
  },
};

export const getDefaultFormValueByOrgType = (orgType, key) => {
  const type = orgType || OrgTypes.DENOUNCE;
  const values = defaultFormValues[type];
  return values[key] || "";
};

export const getDefaultFormValuesByOrgType = (orgType) => {
  const type = orgType || OrgTypes.DENOUNCE;
  return defaultFormValues[type];
};

export const DEFAULT_COLOR = "#145388";
