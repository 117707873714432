export default {
  ADMIN_LOGIN: "ADMIN_LOGIN",
  ADMIN_LOGOUT: "ADMIN_LOGOUT",
  ADMIN_ACCOUNT: "ADMIN_ACCOUNT",
  ADMIN_MENU_ORGS: "ADMIN_MENU_ORGS",
  ADMIN_MENU_WORKFLOW_SUBMISSIONS: "ADMIN_MENU_WORKFLOW_SUBMISSIONS",
  ADMIN_MENU_WORKFLOW_NEW_SUBMISSION: "ADMIN_MENU_WORKFLOW_NEW_SUBMISSION",
  ADMIN_ORG_NEW: "ADMIN_ORG_NEW",
  ADMIN_ORG_TAB_GENERAL: "ADMIN_ORG_TAB_GENERAL",
  ADMIN_ORG_TAB_LOCATIONS: "ADMIN_ORG_TAB_LOCATIONS",
  ADMIN_ORG_TAB_RELATIONSHIPS: "ADMIN_ORG_TAB_RELATIONSHIPS",
  ADMIN_ORG_TAB_TYPES: "ADMIN_ORG_TAB_TYPES",
  ADMIN_ORG_TAB_CUSTOM_FIELDS: "ADMIN_ORG_TAB_CUSTOM_FIELDS",
  ADMIN_ORG_TAB_MEMBERS: "ADMIN_ORG_TAB_MEMBERS",
  ADMIN_ORG_TAB_VOICE_SETTINGS: "ADMIN_ORG_TAB_VOICE_SETTINGS",
  ADMIN_ORG_TAB_FORM_SETTINGS: "ADMIN_ORG_TAB_FORM_SETTINGS",
  ADMIN_ORG_TAB_WHATSAPP: "ADMIN_ORG_TAB_WHATSAPP",
  ADMIN_ORG_TAB_MARKETING_ASSETS: "ADMIN_ORG_TAB_MARKETING_ASSETS",
  ADMIN_ORG_TAB_STATUS_PAGE: "ADMIN_ORG_TAB_STATUS_PAGE",
  ADMIN_ORG_TAB_HOME_CONFIG: "ADMIN_ORG_TAB_HOME_CONFIG",
  ADMIN_ORG_TAB_FORM_PAGE_CONFIG: "ADMIN_ORG_TAB_FORM_PAGE_CONFIG",
  ADMIN_ORG_TAB_APP_SETTINGS_CONFIG: "ADMIN_ORG_TAB_APP_SETTINGS_CONFIG",
  ADMIN_ORG_TAB_SUBMISSION_FEEDBACK: "ADMIN_ORG_TAB_SUBMISSION_FEEDBACK",
  ADMIN_ORG_TAB_COMMITTEE_PAGE_CONFIG: "ADMIN_ORG_TAB_COMITTEE_PAGE_CONFIG",
};
