import { ExtractDataService } from "@ouvidor-digital/persistence-services";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import { NotificationManager } from "../../components/common/react-notifications";
import { db, functions, perf } from "../../helpers/Firebase";
import { intlMessages } from "../../helpers/IntlMessages";
import { EXPORT_DATA, EXPORT_DATA_BY_ORG } from "../actions";

import { getExportDataByOrg, getExportDataByOrgSuccess, getExportDataError, getExportDataSuccess } from "./actions";
const extractDataService = new ExtractDataService(db, perf);

function* exportData(action) {
  const { orgId } = action.payload;
  try {
    const exportDataFunction = functions.httpsCallable("requestDataExport");

    const { data } = yield call(exportDataFunction, { orgId });
    NotificationManager.secondary(
      "Os dados estarão disponíveis para download em breve.",
      intlMessages["general.done"],
      5000,
      () => {},
      null,
      "filled rounded-small",
    );
    yield put(getExportDataByOrg(orgId));
    yield put(getExportDataSuccess(data));
  } catch (error) {
    NotificationManager.error(
      "Não conseguimos exportar os dados da organização.",
      intlMessages["general.error"],
      5000,
      () => {},
      null,
      "filled rounded-small",
    );
    console.error("Error exporting data:", error);
    yield put(getExportDataError(error));
  }
}

function* getAllExportsData(action) {
  const { orgId } = action.payload;

  try {
    const extracts = yield call(extractDataService.getByOrgIdAsync, orgId);

    yield put(getExportDataByOrgSuccess(extracts));
  } catch (error) {
    NotificationManager.error(
      "Erro ao buscar as exportações da organização.",
      intlMessages["general.error"],
      5000,
      () => {},
      null,
      "filled rounded-small",
    );
    console.error("Error exporting data:", error);
    yield put(getExportDataError(error));
  }
}

export function* watchExportData() {
  yield takeEvery(EXPORT_DATA, exportData);
}

export function* watchGetAllExportsData() {
  yield takeEvery(EXPORT_DATA_BY_ORG, getAllExportsData);
}

export default function* rootSaga() {
  yield all([fork(watchExportData), fork(watchGetAllExportsData)]);
}
