import {
  GET_HOME_PAGE_SETTINGS,
  GET_HOME_PAGE_SETTINGS_ERROR,
  GET_HOME_PAGE_SETTINGS_SUCCESS,
  SET_HOME_PAGE_SETTINGS,
} from "../actions";

export const getHomePageSettings = (orgId, lang) => ({
  type: GET_HOME_PAGE_SETTINGS,
  payload: { orgId, lang },
});

export const getHomePageSettingsSuccess = (orgId, homePageSettings) => ({
  type: GET_HOME_PAGE_SETTINGS_SUCCESS,
  payload: { orgId, homePageSettings },
});

export const getHomePageSettingsError = (error) => ({
  type: GET_HOME_PAGE_SETTINGS_ERROR,
  payload: error,
});

export const setHomePageSettings = (orgId, homePageSettings, lang) => ({
  type: SET_HOME_PAGE_SETTINGS,
  payload: { orgId, homePageSettings, lang },
});
