import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";

import AppLocale from "../lang";
const locale = localStorage.getItem("currentLanguage") || "pt";
const { messages } = AppLocale[locale];

const InjectMessage = (props) => <FormattedMessage {...props} />;

export default injectIntl(InjectMessage, {
  withRef: false,
});

export const intlMessages = messages;
