import {
  EXPORT_DATA,
  EXPORT_DATA_BY_ORG,
  EXPORT_DATA_BY_ORG_ERROR,
  EXPORT_DATA_BY_ORG_SUCCESS,
  EXPORT_DATA_ERROR,
  EXPORT_DATA_SUCCESS,
} from "../actions";

export const getExportData = (orgId) => ({
  type: EXPORT_DATA,
  payload: { orgId },
});

export const getExportDataSuccess = (data) => ({
  type: EXPORT_DATA_SUCCESS,
  payload: { data },
});

export const getExportDataError = (error) => ({
  type: EXPORT_DATA_ERROR,
  payload: { error },
});

export const getExportDataByOrg = (orgId) => ({
  type: EXPORT_DATA_BY_ORG,
  payload: { orgId },
});

export const getExportDataByOrgSuccess = (data) => ({
  type: EXPORT_DATA_BY_ORG_SUCCESS,
  payload: { data },
});

export const getExportDataByOrgError = (error) => ({
  type: EXPORT_DATA_BY_ORG_ERROR,
  payload: { error },
});
