import {
  GET_FORM_PAGE_SETTINGS,
  GET_FORM_PAGE_SETTINGS_ERROR,
  GET_FORM_PAGE_SETTINGS_SUCCESS,
  SET_FORM_PAGE_SETTINGS,
  SET_FORM_PAGE_SETTINGS_ERROR,
  SET_FORM_PAGE_SETTINGS_SUCCESS,
} from "../actions";

export const getFormPageSettings = (orgId, lang) => ({
  type: GET_FORM_PAGE_SETTINGS,
  payload: { orgId, lang },
});

export const getFormPageSettingsSuccess = (orgId, formPageSettings) => ({
  type: GET_FORM_PAGE_SETTINGS_SUCCESS,
  payload: { orgId, formPageSettings },
});

export const getFormPageSettingsError = (error) => ({
  type: GET_FORM_PAGE_SETTINGS_ERROR,
  payload: { error },
});

export const setFormPageSettings = (orgId, formPageSettings, lang) => ({
  type: SET_FORM_PAGE_SETTINGS,
  payload: { orgId, formPageSettings, lang },
});

export const setFormPageSettingsSuccess = (orgId, formPageSettings) => ({
  type: SET_FORM_PAGE_SETTINGS_SUCCESS,
  payload: { orgId, formPageSettings },
});

export const setFormPageSettingsError = (error) => ({
  type: SET_FORM_PAGE_SETTINGS_ERROR,
  payload: { error },
});
