import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import {
  GET_FILE_DATA,
  getFileDataError,
  getFileDataSuccess,
  UPLOAD_FILE,
  uploadFileError,
  uploadFileSuccess,
} from "../actions";
import { addOrgMediaAsync, getOrgMediaMetadataAsync } from "../services/MediaService";

function* uploadFile({ payload }) {
  const { file, orgId, path, fileName } = payload;

  const pathString = path.reduce((prev, current) => {
    return `${prev}/${current}`;
  });

  const pathStorage = `${orgId}/${pathString}`;

  // image metadata
  const customMetadata = {
    name: fileName,
    type: file.type ? file.type : "NOT SUPPORTED",
    size: file.size ? file.size : "NOT SUPPORTED",
    height: file.height ? file.height : "NOT SUPPORTED",
    width: file.width ? file.width : "NOT SUPPORTED",
  };

  try {
    // upload file
    const url = yield call(addOrgMediaAsync, file, pathStorage, fileName, customMetadata);
    const metadata = yield call(getOrgMediaMetadataAsync, pathStorage, fileName);
    const content = {
      url,
      metadata,
    };
    yield put(uploadFileSuccess(orgId, pathString, fileName, content));
    // todo: add upload notification
  } catch (error) {
    console.error(error);
    yield put(uploadFileError(error));
  }
}

function* getFileData({ payload }) {
  const { orgId, path, fileName } = payload;

  try {
    yield put(getFileDataSuccess(orgId, path, fileName, {}));

    // todo: add upload notification
  } catch (error) {
    console.error(error);
    yield put(getFileDataError(error));
  }
}

export function* watchUploadFile() {
  yield takeEvery(UPLOAD_FILE, uploadFile);
}

export function* watchGetFileData() {
  yield takeEvery(GET_FILE_DATA, getFileData);
}

export default function* rootSaga() {
  yield all([fork(watchUploadFile), fork(watchGetFileData)]);
}
