// PROD
import firebase from "firebase/app";

import "firebase/app";
import "firebase/database";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "firebase/storage";
import "firebase/performance";
import "firebase/analytics";
import "firebase/remote-config";

// Local
// import firebase from 'firebase';

const devFirebaseConfig = {
  apiKey: "AIzaSyCkY1mNtu9K4u30wiNcu4zBSpiQCMVYmyA",
  authDomain: "od-portal-hmg.firebaseapp.com",
  databaseURL: "https://od-portal-hmg.firebaseio.com",
  projectId: "od-portal-hmg",
  storageBucket: "od-portal-hmg.appspot.com",
  messagingSenderId: "423975032052",
  appId: "1:423975032052:web:580bf83cd923a333c93aa1",
  measurementId: "G-LWTP9PWE0F",
};

const devFirebaseConfigWhistleOn = {
  apiKey: "AIzaSyATSeIVIzuHHUJsa50x7LbOl9xitfr4eWg",
  authDomain: "wo-hmg.firebaseapp.com",
  projectId: "wo-hmg",
  storageBucket: "wo-hmg.appspot.com",
  messagingSenderId: "368372887142",
  appId: "1:368372887142:web:2a7c59293850cb22e8c7a7",
  measurementId: "G-SL2L4HRNQ6",
};

const prodFirebaseConfig = {
  apiKey: "AIzaSyBYBbDb-dhaccs_ScGlp7AwyjGXilKePlQ",
  authDomain: "ouvidor-digital-br.firebaseapp.com",
  databaseURL: "https://ouvidor-digital-br.firebaseio.com",
  projectId: "ouvidor-digital-br",
  storageBucket: "ouvidor-digital-br.appspot.com",
  messagingSenderId: "473818269785",
  appId: "1:473818269785:web:236f00a1d0dad1c0594948",
  measurementId: "G-45HKNW6RZ6",
};

const prodFirebaseConfigWhistleOn = {
  apiKey: "AIzaSyBItPfA9P7aUO6YGiy4TSyw3s74t5N2c-E",
  authDomain: "whistleon-eu.firebaseapp.com",
  projectId: "whistleon-eu",
  storageBucket: "whistleon-eu.appspot.com",
  messagingSenderId: "1005359546513",
  appId: "1:1005359546513:web:d7e804ad35e66cfc87bf30",
  measurementId: "G-YCHG1Y0YEQ",
};

const env = process.env.REACT_APP_CUSTOM_NODE_ENV || process.env.NODE_ENV;
export let domainName;
let firebaseSettings = {};

switch (env) {
  case "production":
    firebaseSettings = prodFirebaseConfig;
    domainName = "OD";
    break;
  case "whistleon-dev":
    firebaseSettings = devFirebaseConfigWhistleOn;
    domainName = "WO";
    break;
  case "whistleon-prod":
    firebaseSettings = prodFirebaseConfigWhistleOn;
    domainName = "WO";
    break;
  case "staging":
    firebaseSettings = devFirebaseConfig;
    domainName = "OD";
    break;
  default:
    firebaseSettings = devFirebaseConfig;
    domainName = "OD";
    break;
}

firebase.initializeApp(firebaseSettings);

const auth = firebase.auth();
const db = firebase.firestore();
const functions = firebase.functions();
const storage = firebase.storage();
const perf = firebase.performance();
const analytics = firebase.analytics();

if (env === "development") {
  auth.useEmulator("http://localhost:9099/", { disableWarnings: true });
  db.useEmulator("localhost", 8080);
  functions.useEmulator("localhost", 5001);
  storage.useEmulator("localhost", 9199);
}

firebase.auth().onAuthStateChanged(async function (user) {
  if (user) {
    // User is logged
  } else {
    // No user is signed in.
  }
});

const remoteConfig = env != "test" ? firebase.remoteConfig() : null;

if (env != "test") {
  // config remote config
  remoteConfig.settings.minimumFetchIntervalMillis = 3600000;

  remoteConfig.defaultConfig = {
    ai_submission_pre_treatment: true,
  };
}

export { analytics, auth, db, firebase, functions, perf, remoteConfig, storage };
