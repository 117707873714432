import { NotificationManager } from "../../components/common/react-notifications";
import {
  ADD_ORG,
  DELETE_ORG_MEMBER,
  DELETE_ORG_MEMBER_ERROR,
  DELETE_ORG_MEMBER_SUCCESS,
  DISABLE_ORG,
  DISABLE_ORG_ERROR,
  DISABLE_ORG_SUCCESS,
  GET_ORG,
  GET_ORG_ERROR,
  GET_ORG_SUCCESS,
  GET_ORG_USERS,
  GET_ORG_USERS_ERROR,
  GET_ORG_USERS_SUCCESS,
  GET_ORGS,
  GET_ORGS_ERROR,
  GET_ORGS_SUCCESS,
  SET_MEMBER_ORGS,
  SET_MEMBER_ORGS_ERROR,
  SET_MEMBER_ORGS_SUCCESS,
  UPDATE_ORG,
} from "../actions";

const INIT_STATE = {
  list: {},
  orgUsers: {},
  error: "",
  isLoading: true,
};

export default (state = INIT_STATE, action) => {
  let newState = {};
  switch (action.type) {
    case GET_ORGS:
    case GET_ORG:
    case GET_ORG_USERS:
      return { ...state, isLoading: true };

    case GET_ORGS_SUCCESS:
      newState = { ...state, isLoading: false };
      newState.list = action.payload.orgs;

      return newState;
    case SET_MEMBER_ORGS:
      newState = { ...state, isLoading: true };
      return newState;

    case SET_MEMBER_ORGS_SUCCESS:
      newState = { ...state, isLoading: false };

      newState.orgUsers[action.payload.orgId] = [...newState.orgUsers[action.payload.orgId], action.payload.user];

      return newState;

    case SET_MEMBER_ORGS_ERROR:
      return { ...state, isLoading: true, error: action.payload };

    case GET_ORG_USERS_SUCCESS:
      newState = { ...state, isLoading: false, orgUsers: {} };

      newState.orgUsers[action.payload.orgId] = action.payload.users;

      return newState;

    case GET_ORG_SUCCESS:
      newState = { ...state, isLoading: false };
      newState.list = { ...newState.list, [action.payload.org.id]: { ...action.payload.org } };
      return newState;

    case GET_ORGS_ERROR:
    case GET_ORG_ERROR:
    case GET_ORG_USERS_ERROR:
    case DISABLE_ORG_ERROR:
      NotificationManager.error(
        "Ocorreu um erro ao tentar atualizar a organização",
        "Erro",
        5000,
        () => {},
        null,
        "filled rounded-small",
      );
      return { ...state, isLoading: false, error: action.payload };

    case DELETE_ORG_MEMBER_SUCCESS:
      newState = {
        ...state,
        isLoading: false,
        orgUsers: {
          ...state.orgUsers,
          [action.payload.orgId]: (state.orgUsers[action.payload.orgId] || []).filter(
            (oM) => oM.profile.uid !== action.payload.uid,
          ),
        },
      };

      return newState;

    case DELETE_ORG_MEMBER_ERROR:
      NotificationManager.error(
        "Ocorreu um erro ao tentar remover o usuário",
        "Erro",
        5000,
        () => {},
        null,
        "filled rounded-small",
      );
      return { ...state, isLoading: false, error: action.payload };

    case ADD_ORG:
    case UPDATE_ORG:
    case DELETE_ORG_MEMBER:
    case DISABLE_ORG:
      newState = { ...state, isLoading: true };
      return newState;

    case DISABLE_ORG_SUCCESS: {
      const { orgId, disable } = action.payload;
      const currentOrg = state.list[orgId];
      const newOrgList = {
        ...state.list,
        [action.payload.orgId]: { ...currentOrg, isDisabled: disable },
      };
      newState = { ...state, isLoading: false, list: newOrgList };
      NotificationManager.secondary(
        "Organização atualizada com sucesso!",
        "Sucesso",
        5000,
        () => {},
        null,
        "filled rounded-small",
      );
      return newState;
    }

    default:
      return { ...state };
  }
};
