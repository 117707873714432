import {
  GET_FORM_QUESTIONS_SETTINGS,
  GET_FORM_QUESTIONS_SETTINGS_ERROR,
  GET_FORM_QUESTIONS_SETTINGS_SUCCESS,
  GET_FORM_SETTINGS,
  GET_FORM_SETTINGS_ERROR,
  GET_FORM_SETTINGS_SUCCESS,
  UPDATE_FORM_QUESTIONS_SETTINGS,
  UPDATE_FORM_QUESTIONS_SETTINGS_ERROR,
  UPDATE_FORM_QUESTIONS_SETTINGS_SUCCESS,
  UPDATE_FORM_SETTINGS,
  UPDATE_FORM_SETTINGS_ERROR,
  UPDATE_FORM_SETTINGS_SUCCESS,
} from "../actions";

export const getFormSettings = (orgId, id) => ({
  type: GET_FORM_SETTINGS,
  payload: { orgId, id },
});

export const getFormSettingsSuccess = (formSettings, id, orgId) => ({
  type: GET_FORM_SETTINGS_SUCCESS,
  payload: { formSettings, id, orgId },
});

export const getFormSettingsError = (error) => ({
  type: GET_FORM_SETTINGS_ERROR,
  payload: error,
});

export const getFormQuestionsSettings = (org, lang, templateLanguage) => ({
  type: GET_FORM_QUESTIONS_SETTINGS,
  payload: { org, lang, templateLanguage },
});

export const getFormQuestionsSettingsSuccess = (questionsSettings, id, orgId, hasQuestions) => ({
  type: GET_FORM_QUESTIONS_SETTINGS_SUCCESS,
  payload: { questionsSettings, id, orgId, hasQuestions },
});

export const getFormQuestionsSettingsError = (error) => ({
  type: GET_FORM_QUESTIONS_SETTINGS_ERROR,
  payload: error,
});

export const updateFormSettings = (formSettings, org) => ({
  type: UPDATE_FORM_SETTINGS,
  payload: { formSettings, org },
});

export const updateFormSettingsSuccess = (formSettings, orgId, id) => ({
  type: UPDATE_FORM_SETTINGS_SUCCESS,
  payload: { formSettings, orgId, id },
});

export const updateFormSettingsError = (error) => ({
  type: UPDATE_FORM_SETTINGS_ERROR,
  payload: error,
});

export const updateFormQuestionsSettings = (questionsSettings, org, lang) => ({
  type: UPDATE_FORM_QUESTIONS_SETTINGS,
  payload: { questionsSettings, org, lang },
});

export const updateFormQuestionsSettingsSuccess = (questionsSettings, orgId, id) => ({
  type: UPDATE_FORM_QUESTIONS_SETTINGS_SUCCESS,
  payload: { questionsSettings, orgId, id },
});

export const updateFormQuestionsSettingsError = (error) => ({
  type: UPDATE_FORM_QUESTIONS_SETTINGS_ERROR,
  payload: error,
});
