import SimpleCrypto from "simple-crypto-js";
const uKey = "u1";

export default class LocalUserService {
  constructor(localStorage) {
    this.localStorage = localStorage;
    this._cat = "7f1dda97-f5f0-4455-930c-52b7f58797ce";
    this.simpleCrypto = new SimpleCrypto(this._cat);
  }

  saveUserOnLocalStorage = (user) => {
    const now = new Date();
    const expiresIn = new Date(now.getTime() + 60 * 60 * 24 * 1000);

    const item = {
      user: user,
      expiresIn: expiresIn.getTime(),
    };

    this.localStorage.setItem(uKey, this.simpleCrypto.encrypt(JSON.stringify(item)));
  };

  getUserFromLocalStorage = () => {
    const encryptedItem = this.localStorage.getItem(uKey);

    if (!encryptedItem) {
      return undefined;
    }

    const item = this.simpleCrypto.decrypt(encryptedItem);
    const now = new Date();
    const expiresIn = new Date(item.expiresIn);
    if (now > expiresIn) {
      this.removeUserFromLocalStorage();
      return undefined;
    }

    return item.user;
  };

  removeUserFromLocalStorage = () => {
    this.localStorage.removeItem(uKey);
  };
}
