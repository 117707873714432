import {
  GENERATE_AI_SUGGESTION,
  GENERATE_AI_SUGGESTION_ERROR,
  GENERATE_AI_SUGGESTION_SUCCESS,
  GET_AI_SUGGESTION,
  GET_AI_SUGGESTION_ERROR,
  GET_AI_SUGGESTION_SUCCESS,
  UPDATE_SUBMISSION_SUGGESTION,
  UPDATE_SUBMISSION_SUGGESTION_ERROR,
  UPDATE_SUBMISSION_SUGGESTION_SUCCESS,
} from "../actions";

export const getAiSuggestion = (submissionId) => {
  return {
    type: GET_AI_SUGGESTION,
    payload: { submissionId },
  };
};

export const getAiSuggestionSuccess = (submissionId, preTreatmentSuggestions) => {
  return {
    type: GET_AI_SUGGESTION_SUCCESS,
    payload: { submissionId, preTreatmentSuggestions },
  };
};

export const getAiSuggestionError = (error) => {
  return {
    type: GET_AI_SUGGESTION_ERROR,
    payload: error,
  };
};

export const updateSubmissionSuggestion = (submission, feedbackValue) => {
  return {
    type: UPDATE_SUBMISSION_SUGGESTION,
    payload: { submission, feedbackValue },
  };
};

export const updateSubmissionSuggestionSuccess = () => {
  return {
    type: UPDATE_SUBMISSION_SUGGESTION_SUCCESS,
    payload: {},
  };
};

export const updateSubmissionSuggestionError = (error) => {
  return {
    type: UPDATE_SUBMISSION_SUGGESTION_ERROR,
    payload: error,
  };
};

export const generateAiSuggestion = (submission) => {
  return {
    type: GENERATE_AI_SUGGESTION,
    payload: { submission },
  };
};

export const generateAiSuggestionSuccess = (submissionId, preTreatmentSuggestions) => {
  return {
    type: GENERATE_AI_SUGGESTION_SUCCESS,
    payload: { submissionId, preTreatmentSuggestions },
  };
};

export const generateAiSuggestionError = (error) => {
  return {
    type: GENERATE_AI_SUGGESTION_ERROR,
    payload: error,
  };
};
