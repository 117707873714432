import { NotificationManager } from "../../components/common/react-notifications";
import {
  DELETE_WHATSAPP_SETTINGS,
  DELETE_WHATSAPP_SETTINGS_ERROR,
  DELETE_WHATSAPP_SETTINGS_SUCCESS,
  GET_CUSTOM_QUESTIONS_WHATSAPP,
  GET_CUSTOM_QUESTIONS_WHATSAPP_ERROR,
  GET_CUSTOM_QUESTIONS_WHATSAPP_SUCCESS,
  GET_WHATSAPP_SETTINGS,
  GET_WHATSAPP_SETTINGS_ERROR,
  GET_WHATSAPP_SETTINGS_SUCCESS,
  SET_CUSTOM_QUESTIONS_WHATSAPP,
  SET_CUSTOM_QUESTIONS_WHATSAPP_ERROR,
  SET_CUSTOM_QUESTIONS_WHATSAPP_SUCCESS,
  SET_WHATSAPP_CONFIGURATIONS,
  SET_WHATSAPP_CONFIGURATIONS_ERROR,
  SET_WHATSAPP_CONFIGURATIONS_SUCCESS,
  SET_WHATSAPP_SETTINGS,
  SET_WHATSAPP_SETTINGS_ERROR,
  SET_WHATSAPP_SETTINGS_SUCCESS,
} from "../actions";

const INIT_STATE = {
  codes: {},
  error: "",
  isLoading: true,
  customQuestions: [],
};

export default (state = INIT_STATE, action) => {
  let newState = {};
  switch (action.type) {
    case GET_WHATSAPP_SETTINGS:
    case SET_WHATSAPP_SETTINGS:
    case DELETE_WHATSAPP_SETTINGS:
    case GET_CUSTOM_QUESTIONS_WHATSAPP:
    case SET_CUSTOM_QUESTIONS_WHATSAPP:
    case SET_WHATSAPP_CONFIGURATIONS:
      return { ...state, isLoading: true };

    case GET_WHATSAPP_SETTINGS_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const { orgId, codesWhatsappSettings } = action.payload;
      newState = { ...state, isLoading: false };
      newState.codes[orgId] = codesWhatsappSettings;

      return newState;

    case GET_CUSTOM_QUESTIONS_WHATSAPP_SUCCESS:
      return { ...state, isLoading: false, customQuestions: action.payload.customQuestions };

    case SET_WHATSAPP_SETTINGS_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const { codesWhatsappSetting } = action.payload;
      newState = { ...state, isLoading: false };
      if (newState.codes[codesWhatsappSetting.orgId]) {
        const hasWhatsappSettingsCode = newState.codes[codesWhatsappSetting.orgId].filter(
          (wppSetting) => wppSetting.id === action.payload.codesWhatsappSetting.id,
        );
        if (!hasWhatsappSettingsCode || hasWhatsappSettingsCode.length === 0) {
          newState.codes[codesWhatsappSetting.orgId] = [
            ...newState.codes[codesWhatsappSetting.orgId],
            action.payload.codesWhatsappSetting,
          ];
        }
      } else {
        newState.codes[codesWhatsappSetting.orgId] = [];
        newState.codes[codesWhatsappSetting.orgId].push(action.payload.codesWhatsappSetting);
      }

      return newState;
    case DELETE_WHATSAPP_SETTINGS_SUCCESS:
      newState = { ...state, isLoading: false };

      // eslint-disable-next-line no-case-declarations
      let codes = newState.codes[action.payload.orgId];

      codes = codes.filter((stateCode) => {
        return stateCode.id !== action.payload.code;
      });
      newState.codes[action.payload.orgId] = codes;

      return newState;

    case SET_CUSTOM_QUESTIONS_WHATSAPP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        codes: {
          ...state.codes,
          [action.payload.orgId]: state.codes[action.payload.orgId].map((wppSetting) => {
            return {
              ...wppSetting,
              customQuestions: action.payload.customQuestions,
            };
          }),
        },
      };

    case SET_WHATSAPP_SETTINGS_ERROR:
      NotificationManager.error(action.payload, "Erro", 5000, () => {}, null, "filled rounded-small");
      return { ...state, isLoading: false, error: action.payload };

    case GET_WHATSAPP_SETTINGS_ERROR:
      NotificationManager.error(
        "Ocorreu um erro ao buscar as configurações do whatsapp",
        "Erro",
        5000,
        () => {},
        null,
        "filled rounded-small",
      );
      return { ...state, isLoading: false, error: action.payload };

    case DELETE_WHATSAPP_SETTINGS_ERROR:
      NotificationManager.error(
        "Ocorreu um erro ao excluir a configurações do whatsapp",
        "Erro",
        5000,
        () => {},
        null,
        "filled rounded-small",
      );
      return { ...state, isLoading: false, error: action.payload };

    case GET_CUSTOM_QUESTIONS_WHATSAPP_ERROR:
    case SET_CUSTOM_QUESTIONS_WHATSAPP_ERROR:
    case SET_WHATSAPP_CONFIGURATIONS_ERROR:
      return { ...state, isLoading: false, error: action.payload };

    case SET_WHATSAPP_CONFIGURATIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        codes: {
          ...state.codes,
          [action.payload.orgId]: state.codes[action.payload.orgId].map((wppSetting) => {
            return {
              ...wppSetting,
              configurations: action.payload.configurations,
            };
          }),
        },
      };

    default:
      return { ...state };
  }
};
