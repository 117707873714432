import {
  GET_MARKETING_ASSETS,
  GET_MARKETING_ASSETS_ERROR,
  GET_MARKETING_ASSETS_SUCCESS,
  SET_MARKETING_ASSETS,
} from "../actions";

export const getMarketingAssets = (orgId) => ({
  type: GET_MARKETING_ASSETS,
  payload: { orgId: orgId },
});

export const getMarketingAssetsSuccess = (orgId, assets) => ({
  type: GET_MARKETING_ASSETS_SUCCESS,
  payload: { orgId, assets },
});

export const getMarketingAssetsError = (error) => ({
  type: GET_MARKETING_ASSETS_ERROR,
  payload: error,
});

export const setMarketingAssets = (orgId, assets) => ({
  type: SET_MARKETING_ASSETS,
  payload: { orgId, assets },
});
