import Services from "@ouvidor-digital/persistence-services";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import { db } from "../../helpers/Firebase";
import { GET_MARKETING_ASSETS, SET_MARKETING_ASSETS } from "../actions";

import { getMarketingAssetsError, getMarketingAssetsSuccess } from "./actions";

const { MarketingAssetService } = Services;

const marketingAssetService = new MarketingAssetService(db);

function* loadMarketingAssets({ payload }) {
  const { orgId } = payload;

  try {
    const assets = yield call(marketingAssetService.getAllAsync, orgId);

    yield put(getMarketingAssetsSuccess(orgId, assets));
  } catch (error) {
    console.error(error);
    yield put(getMarketingAssetsError(error));
  }
}

function* setMarketingAssets({ payload }) {
  try {
    const { orgId, assets } = payload;

    if (orgId && assets && assets instanceof Array) {
      for (const a of assets) {
        yield call(marketingAssetService.addAsync, orgId, a);
      }

      yield put(getMarketingAssetsSuccess(orgId, assets));
    } else {
      console.error("Não é possível salvar esses materiais da camapnha");
      yield put(getMarketingAssetsError("Não é possível salvar esses materiais da camapnha"));
    }
  } catch (error) {
    console.error(error);
    yield put(getMarketingAssetsError(error));
  }
}

export function* watchGetMarketingAssets() {
  yield takeEvery(GET_MARKETING_ASSETS, loadMarketingAssets);
}

export function* watchSetMarketingAssets() {
  yield takeEvery(SET_MARKETING_ASSETS, setMarketingAssets);
}

export default function* rootSaga() {
  yield all([fork(watchGetMarketingAssets), fork(watchSetMarketingAssets)]);
}
