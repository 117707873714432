import {
  GET_FILE_DATA,
  GET_FILE_DATA_ERROR,
  GET_FILE_DATA_SUCCESS,
  UPLOAD_FILE,
  UPLOAD_FILE_ERROR,
  UPLOAD_FILE_SUCCESS,
} from "../actions";

/*
const files = {
    [orgId]: {
        [path]: {
            [fileName]: {
                url: "https://...",
                metadata: {},
            }
        }
    }
}
*/

// UPLOAD_FILE
export const uploadFile = (file, orgId, path, fileName) => ({
  type: UPLOAD_FILE,
  payload: { file, orgId, path, fileName },
});

export const uploadFileSuccess = (orgId, path, fileName, content) => ({
  type: UPLOAD_FILE_SUCCESS,
  payload: { orgId, path, fileName, content },
});

export const uploadFileError = (error) => ({
  type: UPLOAD_FILE_ERROR,
  payload: error,
});

// GET_FILE_DATA
export const getFileData = (orgId, path, fileName) => ({
  type: GET_FILE_DATA,
  payload: { orgId, path, fileName },
});

export const getFileDataSuccess = (orgId, path, fileName) => ({
  type: GET_FILE_DATA_SUCCESS,
  payload: { orgId, path, fileName },
});

export const getFileDataError = (error) => ({
  type: GET_FILE_DATA_ERROR,
  payload: error,
});
