import { GET_EVENT_TAB_DATA, GET_EVENT_TAB_DATA_ERROR, GET_EVENT_TAB_DATA_SUCCESS } from "../../actions";

export const getEventTabData = (orgId) => ({
  type: GET_EVENT_TAB_DATA,
  payload: orgId,
});
export const getEventTabDataSuccess = (data) => ({
  type: GET_EVENT_TAB_DATA_SUCCESS,
  payload: { data },
});

export const getEventTabDataError = (error) => ({
  type: GET_EVENT_TAB_DATA_ERROR,
  payload: error,
});
