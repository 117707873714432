import {
  LOGIN_USER,
  LOGIN_USER_ERROR,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER,
  RECOVERY_PASSWORD,
  RECOVERY_PASSWORD_ERROR,
  RECOVERY_PASSWORD_SUCCESS,
  REFRESH_USER,
  RESET_PASSWORD,
  RESET_PASSWORD_ERROR,
  RESET_PASSWORD_SUCCESS,
  SET_PREFERENCE,
  SET_PREFERENCE_ERROR,
  SET_PREFERENCE_SUCCESS,
} from "../actions";
//   import { NotificationManager } from "../../components/ReactNotifications";
import LocalUserService from "../services/LocalUserService";

const localUserService = new LocalUserService(localStorage);

const INIT_STATE = {
  user: localUserService.getUserFromLocalStorage(),
  loading: false,
  preferencesLoading: false,
  resetPasswordError: "",
  recoveryPasswordError: "",
  loginError: "",
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_PREFERENCE:
      return { ...state, preferencesLoading: true };
    case SET_PREFERENCE_SUCCESS:
      try {
        const userPreferences = state.user.preferences || {};
        userPreferences[action.payload.newPreference.key][action.payload.newPreference.channel] =
          action.payload.newPreference.value;

        const newUser = { ...state.user };
        newUser.preferences = userPreferences;

        return { ...state, preferencesLoading: false, user: newUser };
      } catch (err) {
        console.error(err);
      }
      return { ...state, preferencesLoading: false };
    case SET_PREFERENCE_ERROR:
      return { ...state, preferencesLoading: false };
    case LOGIN_USER:
    case RECOVERY_PASSWORD:
    case RESET_PASSWORD:
      return { ...state, loading: true };
    case LOGOUT_USER:
      return { ...state, user: null };
    case LOGIN_USER_SUCCESS:
    case REFRESH_USER:
      // notify.success('Login Success');
      return { ...state, loading: false, user: action.payload, loginError: "" };
    case RECOVERY_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload,
        recoveryPasswordError: "",
      };
    case LOGIN_USER_ERROR:
      return { ...state, loading: false, loginError: action.payload };
    case RECOVERY_PASSWORD_ERROR:
      return {
        ...state,
        loading: false,
        recoveryPasswordError: action.payload,
      };
    case RESET_PASSWORD_ERROR:
      return { ...state, loading: false, resetPasswordError: action.payload };
    case RESET_PASSWORD_SUCCESS:
      return { ...state, loading: false, resetPasswordError: "" };
    default:
      return { ...state };
  }
};
