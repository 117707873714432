import { addLocaleData } from "react-intl";

import enLang from "./entries/en-US";
import enRtlLang from "./entries/en-US-rtl";
import esLang from "./entries/es-ES";
import ptLang from "./entries/pt-BR";

const AppLocale = {
  en: enLang,
  es: esLang,
  enrtl: enRtlLang,
  pt: ptLang,
};
addLocaleData(AppLocale.en.data);
addLocaleData(AppLocale.es.data);
addLocaleData(AppLocale.enrtl.data);
addLocaleData(AppLocale.pt.data);

export default AppLocale;
