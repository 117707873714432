import {
  CREATE_COMMITTEE,
  CREATE_COMMITTEE_ERROR,
  CREATE_COMMITTEE_SUCCESS,
  DELETE_COMMITTEE,
  DELETE_COMMITTEE_ERROR,
  DELETE_COMMITTEE_SUCCESS,
  GET_COMMITTEES,
  GET_COMMITTEES_ERROR,
  GET_COMMITTEES_SUCCESS,
  UPDATE_COMMITTEE,
  UPDATE_COMMITTEE_ERROR,
  UPDATE_COMMITTEE_SUCCESS,
} from "../actions";

export const createCommittee = ({
  orgId,
  name,
  description,
  visibleLocationsIds,
  visibleSubmissionTypesIds,
  visibleRelationshipsIds,
  members,
  userProfile,
}) => {
  return {
    type: CREATE_COMMITTEE,
    payload: {
      orgId,
      name,
      description,
      visibleLocationsIds,
      visibleSubmissionTypesIds,
      visibleRelationshipsIds,
      members,
      userProfile,
    },
  };
};

export const createCommitteeSuccess = ({ committee }) => {
  return {
    type: CREATE_COMMITTEE_SUCCESS,
    payload: { committee },
  };
};

export const createCommitteeError = ({ error }) => {
  return {
    type: CREATE_COMMITTEE_ERROR,
    payload: error,
  };
};

export const deleteCommittee = ({ committeeId, orgId, userProfile }) => {
  return {
    type: DELETE_COMMITTEE,
    payload: { committeeId, orgId, userProfile },
  };
};

export const deleteCommitteeSuccess = ({ committeeId, orgId }) => {
  return {
    type: DELETE_COMMITTEE_SUCCESS,
    payload: { committeeId, orgId },
  };
};

export const deleteCommitteeError = ({ error }) => {
  return {
    type: DELETE_COMMITTEE_ERROR,
    payload: error,
  };
};

export const getCommittees = ({ orgId }) => {
  return {
    type: GET_COMMITTEES,
    payload: { orgId },
  };
};

export const getCommitteesSuccess = ({ committees, orgId }) => {
  return {
    type: GET_COMMITTEES_SUCCESS,
    payload: { committees, orgId },
  };
};

export const getCommitteesError = ({ error }) => {
  return {
    type: GET_COMMITTEES_ERROR,
    payload: error,
  };
};

export const updateCommittee = ({ committeeId, orgId, userProfile, updates }) => {
  return {
    type: UPDATE_COMMITTEE,
    payload: { committeeId, orgId, userProfile, updates },
  };
};

export const updateCommitteeSuccess = ({ committee }) => {
  return {
    type: UPDATE_COMMITTEE_SUCCESS,
    payload: { committee },
  };
};

export const updateCommitteeError = ({ error }) => {
  return {
    type: UPDATE_COMMITTEE_ERROR,
    payload: error,
  };
};
